import {
  AttachFile,
  Description,
  Email,
  Info,
  MoreHoriz,
  NoteAdd,
  PhoneAndroid,
  Timeline,
} from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import GenericModal from "components/GenericModal";
import MDTypography from "components/MDTypography";
import moment from "moment";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import LoadingButton from "@mui/lab/LoadingButton";
import { useMutation, useQueryClient } from "react-query";
import { useAppContextController } from "context/AppContext";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import { useNavigate } from "react-router-dom";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";
import fetchSingleApplicant from "layouts/pages/applicants/actions/fetchSingleApplicant";
import AddANoteModal from "layouts/pages/applicants/components/AddANoteModal";
import postNote from "layouts/pages/applicants/actions/postNote";
import touchJob from "layouts/pages/applicants/actions/touchJob";
import ApplicantNotesList from "components/ApplicantNotesList";
import ApplicantActivitiesList from "components/ApplicantActivitiesList";
import MDBadge from "components/MDBadge";
import ApplicantAttachmentsList from "components/ApplicantAttachmentsList";

const ApplicantMoreButton = ({ applicant, onSuccess }) => {
  const [isNotesModalOpen, setNotesModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const { company, currentLoggedUser, currentJob, currentApplicant, setCurrentApplicant } =
    useAppContextController();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isSendMessageModalOpen, setIsSendMessageModalOpen] = useState(false);
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = useState(false);
  const [isAttachmentsModalOpen, setIsAttachmentsModalOpen] = useState(false);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const applicantNotesCount = (applicant?.notes ?? []).length;
  const applicantAttachmentsCount = (applicant?.attachments ?? []).length;

  const options = [
    {
      text: `Notes`,
      icon: (
        <MDBadge
          badgeContent={applicantNotesCount}
          color="error"
          size="xs"
          invisible={!applicantNotesCount}
          // anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          slotProps={{
            badge: {
              sx: { marginTop: "5px" },
            },
          }}
          circular
        >
          <Description fontSize="medium" color="info" />
        </MDBadge>
      ),
      handleClick: (e) => {
        setNotesModalOpen(true);
      },
      disabled: false,
    },
    {
      text: `Activities`,
      icon: <Timeline fontSize="medium" color="error" />,
      handleClick: (e) => {
        setIsActivitiesModalOpen(true);
      },
      disabled: false,
    },

    {
      text: `Attachments`,
      icon: (
        <MDBadge
          badgeContent={applicantAttachmentsCount}
          color="error"
          size="xs"
          invisible={!applicantAttachmentsCount}
          slotProps={{
            badge: {
              sx: { marginTop: "5px" },
            },
          }}
          circular
        >
          <AttachFile fontSize="medium" color="dark" />
        </MDBadge>
      ),
      handleClick: (e) => {
        setIsAttachmentsModalOpen(true);
      },
      disabled: false,
    },
  ];

  // If the current applicant is the same we're applying this quick action, then update it in memory
  const setApplicant = (applData) => {
    if (applicant && currentApplicant && applicant._id === currentApplicant._id) {
      setCurrentApplicant(applData);
    }
  };

  return (
    <>
      <Tooltip title="More actions">
        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            setAnchorEl(e.currentTarget);
          }}
          sx={{ paddingLeft: 0, paddingRight: 0, marginLeft: "10px", marginRight: "10px" }}
        >
          <MoreHoriz fontSize="large" color="dark" />
        </IconButton>
      </Tooltip>
      <CustomMenu
        anchorEl={anchorEl}
        handleClose={(e) => {
          e?.stopPropagation();
          setAnchorEl(null);
        }}
        options={options}
        iconMargin="5px"
      />

      {/* This button prevents onClick events in modals from propagating to parents, triggering the row's onClick */}
      <button
        type="button"
        onClick={(e) => {
          e?.preventDefault();
          e?.stopPropagation();
        }}
      >
        {isNotesModalOpen && (
          <ApplicantNotesList
            currentApplicant={applicant}
            setCurrentApplicant={setApplicant}
            open={isNotesModalOpen}
            setOpen={setNotesModalOpen}
            onSuccess={onSuccess}
          />
        )}
        {isActivitiesModalOpen && (
          <ApplicantActivitiesList
            currentApplicant={applicant}
            open={isActivitiesModalOpen}
            setOpen={setIsActivitiesModalOpen}
          />
        )}
        {isAttachmentsModalOpen && (
          <ApplicantAttachmentsList
            currentApplicant={applicant}
            setCurrentApplicant={setApplicant}
            open={isAttachmentsModalOpen}
            setOpen={setIsAttachmentsModalOpen}
            onSuccess={onSuccess}
          />
        )}
      </button>
    </>
  );
};

export default ApplicantMoreButton;
