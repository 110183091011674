import axios from "axios";

import { baseAxios } from "config";

export const generateApplicantTableReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicants-table`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};

export const generateApplicantChartReport = async (data) => {
  try {
    const res = await baseAxios.post(`/reports/applicants-chart`, data);

    return res.data;
  } catch (err) {
    throw new Error(err.toString());
  }
};
