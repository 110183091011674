import { Grid, IconButton, Stack } from "@mui/material";
import { Cancel } from "@mui/icons-material";

import { dataStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const ShiftJobsItem = ({ row, idx, selectShift, onRemoveShift }) => {
  function getDaysWithTimes(data) {
    return Object.keys(data)
      .filter((day) => data[day].start && data[day].end)
      .map((day) => day.charAt(0).toUpperCase() + day.slice(1, 3));
  }

  return (
    <>
      <Stack
        display="flex"
        direction="row"
        justifyContent="space-between"
        onClick={() => {
          selectShift(idx, row);
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={5}>
            <MDTypography sx={styles.font}>{row.shiftName}</MDTypography>
          </Grid>
          {/* <Grid item sm={3}> */}
          {/*  <MDTypography sx={styles.font}>9:00 AM - 5:00 PM</MDTypography> */}
          {/* </Grid> */}
          <Grid item sm={5}>
            <MDTypography sx={styles.font}>
              {getDaysWithTimes(row.defaultSchedule).join(", ")}
            </MDTypography>
          </Grid>

          <Grid item sm={2}>
            <MDBox textAlign="right">
              <IconButton
                sx={{ p: 0 }}
                onClick={(e) => {
                  e.stopPropagation();
                  onRemoveShift(idx);
                }}
              >
                <Cancel color="error" fontSize="medium" />
              </IconButton>
            </MDBox>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default ShiftJobsItem;
