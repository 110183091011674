/*
Missing these for now:
  Event Report Time (from position on Roster)
  Event Map URL
*/
export const MESSAGE_VARIABLES = [
  {
    name: "Applicant First Name",
    key: "{{ applicantFirstName }}",
    variable: "this.applicant.firstName",
  },
  {
    name: "Applicant Last Name",
    key: "{{ applicantLastName }}",
    variable: "this.applicant.lastName",
  },
  {
    name: "Applicant Email",
    key: "{{ applicantEmail }}",
    variable: "this.applicant.email",
  },
  {
    name: "Applicant Phone",
    key: "{{ applicantPhone }}",
    variable: "this.applicant.phone",
  },
  {
    name: "Applicant Code",
    key: "{{ applicantCode }}",
    variable: "this.applicant.applicantCode",
  },
  {
    name: "Applicant ID",
    key: "{{ applicantId }}",
    variable: "this.applicant._id",
  },
  {
    name: "Event Venue Name",
    key: "{{ eventVenue }}",
    variable: "this.event.venueName",
  },
  {
    name: "Event Name",
    key: "{{ eventName }}",
    variable: "this.event.eventName",
  },
  {
    name: "Event Venue Logo",
    key: "{{ eventVenueLogo }}",
    variable: "this.event.eventImage",
  },
  {
    name: "Event Preview URL",
    key: "{{ eventUrl }}",
    variable: "this.event.eventUrl",
  },
  {
    name: "Event Description",
    key: "{{ eventDescription }}",
    variable: "this.event.description",
  },
  {
    name: "Event Date",
    key: "{{ eventDate }}",
    variable: "this.event.eventDate",
  },
  {
    name: "Event ReportTime",
    key: "{{ eventReportTime }}",
    variable: "this.event.reportTime",
  },
  {
    name: "Event Position",
    key: "{{ eventPosition }}",
    variable: "this.event.position",
  },
  {
    name: "Event Address",
    key: "{{ eventAddress }}",
    variable: "this.event.address",
  },
  {
    name: "Event City",
    key: "{{ eventCity }}",
    variable: "this.event.venueCity",
  },
  {
    name: "Event State",
    key: "{{ eventState }}",
    variable: "this.event.venueState",
  },
  {
    name: "Event Zip Code",
    key: "{{ eventZipCode }}",
    variable: "this.event.zip",
  },
  {
    name: "First Name",
    key: "{{ firstName }}",
    variable: "this.user.firstName",
  },
  {
    name: "Last Name",
    key: "{{ lastName }}",
    variable: "this.user.lastName",
  },
  {
    name: "Email",
    key: "{{ email }}",
    variable: "this.user.emailAddress",
  },
  {
    name: "Sender Phone",
    key: "{{ phone }}",
    variable: "this.user.phone",
  },
  {
    name: "Venue Name",
    key: "{{ venueName }}",
    variable: "this.venue.name",
  },
  {
    name: "Venue ID",
    key: "{{ venueId }}",
    variable: "this.venue.slug",
  },
  {
    name: "Web URL",
    key: "{{ webUrl }}",
    variable: "this.webUrl",
  },

  {
    name: "Job Name",
    key: "{{ jobName }}",
    variable: "this.job.title",
  },
  {
    name: "Job Company Name",
    key: "{{ jobCompany }}",
    variable: "this.job.companyName",
  },
  {
    name: "Job Description",
    key: "{{ jobDescription }}",
    variable: "this.job.description",
  },
  {
    name: "Job Address",
    key: "{{ jobAddress }}",
    variable: "this.job.address",
  },
  {
    name: "Job City",
    key: "{{ jobCity }}",
    variable: "this.job.companyCity",
  },
  {
    name: "Job State",
    key: "{{ jobState }}",
    variable: "this.job.companyState",
  },
  {
    name: "Job Zip Code",
    key: "{{ jobZipCode }}",
    variable: "this.job.zip",
  },

  {
    name: "Task Name",
    key: "{{ taskName }}",
    variable: "this.task.taskName",
  },
  {
    name: "Task Type",
    key: "{{ taskType }}",
    variable: "this.task.taskType",
  },
  {
    name: "Task Due Date",
    key: "{{ taskDueDate }}",
    variable: "this.task.dueDate",
  },
  {
    name: "Task Applicant First Name",
    key: "{{ taskApplicantFirstName }}",
    variable: 'this.task?.applicant?.firstName ?? ""',
  },
  {
    name: "Task Applicant Last Name",
    key: "{{ taskApplicantLastName }}",
    variable: 'this.task?.applicant?.lastName ?? ""',
  },
  {
    name: "Task Description",
    key: "{{ taskDescription }}",
    variable: "this.task.taskDescription",
  },
];
