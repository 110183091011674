import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBadgeValues from "hooks/useBadgeValues";
import FilterCardBar from "components/FilterCardBar";
import PublicIcon from "@mui/icons-material/Public";
import Icon from "@mui/material/Icon";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import LoginIcon from "@mui/icons-material/Login";
import { useAppContextController } from "context/AppContext";
import { cloneDeep } from "lodash";

export const initialCards = [
  {
    color: "dark",
    icon: <PublicIcon fontSize="large" color="black" />,
    title: "All",
    tooltip: "All Users",
    number: 0,
    filterField: "status",
    filterValue: "Active",
  },
  {
    color: "secondary",
    icon: <SmartphoneIcon fontSize="large" color="secondary" />,
    title: "Mobile",
    tooltip: "Mobile Users",
    number: 0,
    filterField: "platform",
    filterValue: "ios;android",
    canCombine: true,
  },
  {
    color: "info",
    icon: (
      <Icon fontSize="large" color="info">
        account_circle
      </Icon>
    ),
    title: "Users",
    tooltip: "Employee Users",
    default: true,
    number: 0,
    filterField: "userType",
    filterValue: "User",
    canCombine: true,
  },
  {
    color: "warning",
    icon: <SupervisedUserCircleIcon fontSize="large" color="warning" />,
    title: "Admin",
    tooltip: "Administrators",
    number: 0,
    filterField: "userType",
    filterValue: "Admin",
    canCombine: true,
  },
  {
    color: "error",
    icon: <SupervisorAccountIcon fontSize="large" color="error" />,
    title: "Master",
    tooltip: "Master Users",
    number: 0,
    filterField: "userType",
    filterValue: "Master",
    canCombine: true,
  },
  {
    color: "success",
    icon: <LoginIcon fontSize="large" color="success" />,
    title: "Logged In",
    tooltip: "Users That Have Logged In",
    number: 0,
    filterField: "loginVerified",
    filterValue: "Yes",
  },
];

const UsersFilterCards = ({ setFilters, setPage, filters }) => {
  const { setCurrentUser, company } = useAppContextController();
  const navigate = useNavigate();

  const initialCardsLocal = useMemo(() => {
    const result = cloneDeep(initialCards);
    if (company?.companyType === "Company") {
      result[2].default = false;
      result[4].default = true;
    }
    return result;
  }, [company?.companyType]);

  const [refreshBadges, setRefreshBadges] = useState(false);
  const { cards } = useBadgeValues({ initialCards: initialCardsLocal, filters, entity: "users", refreshBadges });

  const initialCardIndex = initialCardsLocal.findIndex((card) => card.default);

  const setUsersFilters = (filter) => {
    navigate("/users");
    setCurrentUser(null);
    setFilters(filter);
    setRefreshBadges((prev) => !prev);
  };

  if (!company?.companyType) return null;
  
  return (
    <span>
      <FilterCardBar
        cards={cards}
        parent="Users"
        bgColor="primary"
        setFilters={setUsersFilters}
        setPage={setPage}
        parentComponent="Users"
        initialIndex={initialCardIndex}
      />
    </span>
  );
};

export default UsersFilterCards;
