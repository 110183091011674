import { Box, Button, IconButton, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useAppContextController } from "context/AppContext";
import GenericModal from "components/GenericModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Close, Save } from "@mui/icons-material";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useMutation, useQuery, useQueryClient } from "react-query";
import enrollMembersToEvent from "api/events/enrollMembersToEvent";
import { useSnackbar } from "notistack";
import updateEventRosterBulk from "layouts/pages/events/actions/updateEventRosterBulk";
import moment from "moment";
import { PartnerMembersRosterList } from "./PartnerMembersRosterList";
import rosterClockInBulk from "../../actions/rosterClockInBulk";
import ClockInOutCustomButton from "./ClockInOutCustomButton";

export const PartnerMembersRosterModal = ({
  isOpen,
  toggleModal,
  partner,
  allMemberRoster,
  clockInOut,
  clockInOutAtEventTime,
  positionsRequested,
  isPartnerInRoster,
  eventDay,
  toggleRefreshBadges,
  defaultStartTime,
}) => {
  const { companyType, currentEvent, currentLoggedUser, userType } = useAppContextController();

  const [takenPositions, clockedInPositions, clockedOutPositions, takenPositionsThisPartner] =
    useMemo(() => {
      if (!allMemberRoster?.result) return [0, 0, 0, 0];

      const onRoster = [];
      const clockIn = [];
      const clockOut = [];
      const onRosterThisPartner = [];

      allMemberRoster.result.forEach((ros) => {
        if (ros.status === "Roster") {
          onRoster.push(ros);

          if (partner && ros.partnerSlug === partner.slug) {
            onRosterThisPartner.push(ros);
            if (ros.timeIn) {
              clockIn.push(ros);
            }
            if (ros.timeOut) {
              clockOut.push(ros);
            }
          }
        }
      });

      return [
        onRoster?.length ?? 0,
        clockIn?.length ?? 0,
        clockOut?.length ?? 0,
        onRosterThisPartner?.length ?? 0,
      ];
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(allMemberRoster?.result), partner]);
  const availablePositions = Math.max(positionsRequested - takenPositions, 0);

  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const insertRosterBulkMutation = useMutation(updateEventRosterBulk, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Roster updated successfully", { variant: "success" });
      await queryClient.invalidateQueries(["rosterApplicants"]);
      setSelectedApplicants([]);
    },
    onError: (error) => enqueueSnackbar(`Something went wrong`, { variant: "error" }),
  });

  //
  const clockInRosterBulkMutation = useMutation(rosterClockInBulk, {
    // eslint-disable-next-line
    onSuccess: async (_, { data }) => {
      enqueueSnackbar("Roster updated successfully", { variant: "success" });
      await queryClient.invalidateQueries(["rosterApplicants"]);
      setSelectedApplicants([]);
    },
    onError: (error) => enqueueSnackbar(`Something went wrong`, { variant: "error" }),
  });

  const { mutate: enrollMemberMutation } = useMutation(enrollMembersToEvent, {
    onSuccess: async ({ data: response }, variables) => {
      if (response.success) {
        const { message = "", status: messageStatus = "" } = response;
        enqueueSnackbar(message, {
          variant: messageStatus.toLowerCase(),
          autoHideDuration: 5000,
        });
        await queryClient.invalidateQueries(["rosterApplicants"]);
        toggleRefreshBadges();
        setSelectedApplicants([]);
      }
    },
    onError: (err) => enqueueSnackbar(`Something went wrong`, { variant: "error" }),
    mutationKey: "eventStatusMutation",
  });

  const allOptions = {
    fetchAll: true,
    filters: {
      status: "Member",
      "partners.partnerSlug": partner?.slug,
    },
    orderByOverride: "firstName:asc,lastName:asc",
    useElemMatch: true,
  };

  const {
    data: fetchedMembers,
    isLoading,
    refetch: refetchMembers,
  } = useQuery(["members", allOptions], () => fetchApplicants(allOptions), {
    disabled: !partner,
    select: (data) => {
      if (!data?.data) return [];
      const newData = data.data.map((mem) => {
        const partnerItem = (mem.partners ?? []).find((p) => p.partnerSlug === partner?.slug);
        let partnerStatus = "";
        if (partnerItem) {
          partnerStatus = partnerItem.status;
        }
        return {
          ...mem,
          partnerStatus,
        };
      });
      return newData;
    },
  });

  const { memberRoster, memberRosterIndexed } = useMemo(() => {
    const memberRosterIndexedLocal = {};
    if (!allMemberRoster || !partner) return { memberRoster: [], memberRosterIndexed: {} };
    const currentMemberRoster = allMemberRoster?.result?.filter(
      (mr) => partner.slug === mr.partnerSlug
    );
    if (!currentMemberRoster?.length) return { memberRoster: [], memberRosterIndexed: {} };
    currentMemberRoster.map((mr) => {
      if (!mr?.id) return null;
      memberRosterIndexedLocal[mr.id] = mr;
      return mr;
    });
    return {
      memberRoster: currentMemberRoster ?? [],
      memberRosterIndexed: memberRosterIndexedLocal,
    };
  }, [partner, allMemberRoster]);

  const {
    allSelectedAreInRoster,
    allSelectedAreClockedIn,
    allSelectedAreClockedOut,
    canAddToRoster,
    canClockInGroup,
    canClockOutGroup,
  } = useMemo(() => {
    let allSelectedAreInRosterLocal = true;
    let allSelectedAreClockedInLocal = true;
    let allSelectedAreClockedOutLocal = true;
    let canAddToRosterLocal = false;
    let canClockInGroupLocal = false;
    let canClockOutGroupLocal = false;

    if (!selectedApplicants?.length)
      return {
        allSelectedAreInRoster: false,
        allSelectedAreClockedIn: false,
        allSelectedAreClockedOut: false,
        canAddToRoster: canAddToRosterLocal,
        canClockInGroup: canClockInGroupLocal,
        canClockOutGroup: canClockOutGroupLocal,
      };

    // At least one selected, can press first button
    canAddToRosterLocal = true;

    for (let index = 0; index < selectedApplicants.length; index += 1) {
      const element = selectedApplicants[index];
      if (!memberRosterIndexed[element]) {
        allSelectedAreInRosterLocal = false;
        allSelectedAreClockedInLocal = false;
        allSelectedAreClockedOutLocal = false;
      } else {
        if (!memberRosterIndexed[element].timeIn) {
          canClockInGroupLocal = true;
          allSelectedAreClockedInLocal = false;
        }
        if (memberRosterIndexed[element].timeIn && !memberRosterIndexed[element].timeOut) {
          canClockOutGroupLocal = true;
        }
        if (!memberRosterIndexed[element].timeOut) {
          allSelectedAreClockedOutLocal = false;
        } else allSelectedAreClockedInLocal = false;
      }
    }

    return {
      allSelectedAreInRoster: allSelectedAreInRosterLocal,
      allSelectedAreClockedIn: allSelectedAreClockedInLocal,
      allSelectedAreClockedOut: allSelectedAreClockedOutLocal,
      canAddToRoster: canAddToRosterLocal,
      canClockInGroup: canClockInGroupLocal,
      canClockOutGroup: canClockOutGroupLocal,
    };
  }, [memberRosterIndexed, selectedApplicants]);

  const members = useMemo(() => {
    return (fetchedMembers ?? []).filter((mem) => {
      // If partner is NOT in Roster, and it's inactive, don't show it
      if (mem.partnerStatus === "Inactive" && memberRosterIndexed?.[mem._id]?.status !== "Roster") {
        return false;
      }

      return true;
    });
  }, [fetchedMembers, memberRosterIndexed]);

  const disableSelection = useMemo(() => {
    let selectedToAdd = 0;
    for (let index = 0; index < selectedApplicants.length; index += 1) {
      const element = selectedApplicants[index];
      if (!memberRosterIndexed[element]) {
        selectedToAdd += 1;
      }
    }
    return (availablePositions ?? 0) - selectedToAdd < 0;
  }, [availablePositions, memberRosterIndexed, selectedApplicants]);

  const processSingleClockInOut = (rosterObjParam, field, customDateString) => {
    const rosterObj = { ...rosterObjParam };
    // if primaryPosition is set on the rosterObj, we need to get the check-in time
    const reportTime = eventTime;
    const fullReportTime = new Date(currentEvent.eventDate).toISOString();

    rosterObj.agent = `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`;
    if (!rosterObj?.[field] || customDateString === "") {
      // this is where we need to set the clock in time to the report time if earlier
      const currentTime = moment().toISOString().split("T")[1];
      let ISOTime;
      if (
        customDateString != null &&
        field === "timeIn" &&
        userType === "User" &&
        (new Date().toISOString() < fullReportTime ||
          moment().toISOString().split("T")[0] > eventDate)
      ) {
        ISOTime = fullReportTime;
      } else ISOTime = customDateString ?? `${eventDate}T${currentTime}`;
      rosterObj[field] = ISOTime;
      // setTime(rosterObj[field]);
    }
    return rosterObj;
  };

  const clockOutBulk = async (e, timeString) => {
    const requestBody = [];
    if (!selectedApplicants?.length) return;

    for (let index = 0; index < selectedApplicants.length; index += 1) {
      const element = selectedApplicants[index];

      const currentRosterItem = memberRoster?.find((item) => item.id === element);
      if (
        currentRosterItem &&
        currentRosterItem.timeIn &&
        (!currentRosterItem.timeOut || timeString === "")
      ) {
        const processedRosterItem = processSingleClockInOut(
          currentRosterItem,
          "timeOut",
          timeString
        );
        if (processedRosterItem) {
          if (processedRosterItem.timeOut === "") {
            processedRosterItem.timeOut = null;
          } else processedRosterItem.timeOut = processedRosterItem.timeOut || new Date();
          requestBody.push(processedRosterItem);
        }
      }
    }

    await insertRosterBulkMutation.mutateAsync({ id: currentEvent?._id, data: requestBody });
  };

  const clockInBulk = async (e, timeString) => {
    const requestBody = [];
    if (!selectedApplicants?.length) return;

    for (let index = 0; index < selectedApplicants.length; index += 1) {
      const element = selectedApplicants[index];

      const currentRosterItem = memberRoster?.find((item) => item.id === element);
      if (currentRosterItem && (!currentRosterItem.timeIn || timeString === "")) {
        const processedRosterItem = processSingleClockInOut(
          currentRosterItem,
          "timeIn",
          timeString
        );
        if (processedRosterItem) {
          if (processedRosterItem.timeIn === "") {
            processedRosterItem.timeIn = null;
          } else processedRosterItem.timeIn = processedRosterItem.timeIn || new Date();
          requestBody.push(processedRosterItem);
        }
      }
    }

    if (timeString != null) {
      await insertRosterBulkMutation.mutateAsync({ id: currentEvent?._id, data: requestBody });
    } else {
      await clockInRosterBulkMutation.mutateAsync({ id: currentEvent?._id, data: requestBody });
    }
  };

  const onPressAddRoster = () => {
    if (selectedApplicants?.length) {
      const validIds = [];
      for (let index = 0; index < selectedApplicants.length; index += 1) {
        const applId = selectedApplicants[index];
        if (memberRosterIndexed[applId]?.status !== "Roster") {
          validIds.push(applId);
        }
      }
      const body = {
        requestType: "Roster",
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
        memberIds: validIds,
      };
      enrollMemberMutation({
        eventUrl: currentEvent?.eventUrl,
        partnerSlug: partner.slug,
        body,
      });
    }
  };

  const onPressRemoveFromRoster = () => {
    if (selectedApplicants?.length) {
      const validIds = [];
      for (let index = 0; index < selectedApplicants.length; index += 1) {
        const applId = selectedApplicants[index];
        if (memberRosterIndexed[applId]?.status) {
          validIds.push(applId);
        }
      }
      const body = {
        requestType: "Not Roster",
        agent: `${currentLoggedUser.firstName} ${currentLoggedUser.lastName}`,
        createAgent: currentLoggedUser._id,
        memberIds: validIds,
      };
      enrollMemberMutation({
        eventUrl: currentEvent?.eventUrl,
        partnerSlug: partner.slug,
        body,
      });
    }
  };

  useEffect(() => {
    if (currentEvent?._id && currentEvent?.eventDate) {
      // setVenueSlug(currentEvent.venueSlug);
      // setMongoId(currentEvent._id);
      const gmtDate = new Date(currentEvent.eventDate);
      const offset = gmtDate.getTimezoneOffset();
      const MS_PER_MINUTE = 60000;
      const localDate = new Date(gmtDate - offset * MS_PER_MINUTE);

      if (localDate) setEventDate(localDate?.toISOString().split("T")[0]);
      if (gmtDate) setEventTime(gmtDate?.toISOString().split("T")[1]);
    }
  }, [currentEvent]);

  return (
    <>
      <GenericModal
        open={isOpen}
        setOpen={toggleModal}
        overflow="auto"
        width="70vw"
        header={
          <Box
            display="flex"
            sx={{ flex: 1, justifyContent: "space-between", alignItems: "flex-start" }}
          >
            <MDBox sx={{ mt: 2, mb: 1, ml: 1 }}>
              <MDTypography variant="h6" color="dark">
                {`${currentEvent?.venueName} - ${currentEvent?.eventName}`}
              </MDTypography>
              <MDTypography variant="h5" color="dark">
                {`${partner?.name}: Member Attendance`}
              </MDTypography>
            </MDBox>
            <IconButton onClick={toggleModal}>
              <Close />
            </IconButton>
          </Box>
        }
        body={
          <Box height="calc(80vh - 10rem)" minHeight="24rem" display="flex" flexDirection="column">
            {isPartnerInRoster ? (
              <>
                <Box display="flex" justifyContent="space-between">
                  <MDTypography
                    variant="h5"
                    color={disableSelection ? "error" : "dark"}
                  >{`Available positions: ${availablePositions}`}</MDTypography>
                  <Stack direction="row" justifyContent="flex-end" spacing={2}>
                    {allSelectedAreInRoster ? (
                      <Button
                        // type="submit"
                        color="error"
                        variant="contained"
                        onClick={onPressRemoveFromRoster}
                        style={{ color: "white" }}
                        disabled={!canAddToRoster}
                      >
                        Remove from Roster
                      </Button>
                    ) : (
                      <Button
                        // type="submit"
                        variant="contained"
                        onClick={onPressAddRoster}
                        style={{ color: "white" }}
                        disabled={!canAddToRoster || disableSelection}
                      >
                        Add to Roster
                      </Button>
                    )}
                    {allSelectedAreClockedIn ? (
                      <Button
                        // type="submit"
                        color="error"
                        variant="contained"
                        onClick={(e) => clockInBulk(e, "")}
                        style={{ color: "white" }}
                        disabled={!canAddToRoster}
                      >
                        Undo Clock In
                      </Button>
                    ) : (
                      <ClockInOutCustomButton
                        field="timeIn"
                        disabled={
                          !canClockInGroup || currentEvent?.eventType !== "Event" || !eventDay
                        }
                        clockInOut={clockInBulk}
                        defaultDate={defaultStartTime ?? currentEvent?.eventDate}
                      />
                    )}
                    {allSelectedAreClockedOut ? (
                      <Button
                        // type="submit"
                        color="error"
                        variant="contained"
                        onClick={(e) => clockOutBulk(e, "")}
                        style={{ color: "white" }}
                        disabled={!canAddToRoster}
                      >
                        Undo Clock-Out
                      </Button>
                    ) : (
                      <ClockInOutCustomButton
                        field="timeOut"
                        disabled={
                          !canClockOutGroup || currentEvent?.eventType !== "Event" || !eventDay
                        }
                        clockInOut={clockOutBulk}
                        defaultDate={defaultStartTime ?? currentEvent?.eventDate}
                      />
                    )}
                  </Stack>
                </Box>
                <Box display="flex" gap={2}>
                  <MDTypography
                    variant="h6"
                    color="dark"
                  >{`Clocked-In: ${clockedInPositions}/${takenPositionsThisPartner}`}</MDTypography>
                  <MDTypography
                    variant="h6"
                    color="dark"
                  >{`Clocked-Out: ${clockedOutPositions}/${takenPositionsThisPartner}`}</MDTypography>
                </Box>
              </>
            ) : (
              <Box display="flex">
                <MDTypography variant="h5" color={disableSelection ? "error" : "dark"}>
                  Partner is not on Roster
                </MDTypography>
              </Box>
            )}
            <Box pt={2} display="flex" flex={1}>
              <PartnerMembersRosterList
                applicants={members}
                rosterApplicants={memberRoster}
                rosterApplicantsIndexed={memberRosterIndexed}
                isLoading={isLoading}
                eventDate={eventDate}
                eventTime={eventTime}
                selectedApplicants={selectedApplicants}
                setSelectedApplicants={setSelectedApplicants}
                clockInOut={clockInOut}
                clockInOutAtEventTime={clockInOutAtEventTime}
                isPartnerInRoster={isPartnerInRoster}
                eventDay={eventDay}
              />
            </Box>
            {/* <Box pt={2} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={<Save />}
                style={{ color: "white" }}
                sx={{ padding: "0.5rem 1rem" }}
                onClick={() => {}}
              >
                Save
              </Button>
            </Box> */}
          </Box>
        }
      />
    </>
  );
};
