import * as yup from "yup";
import moment from "moment";
import { MAX_DATE, MIN_DATE } from "components/ReportModal/fixtures";

export const reportFormSchema = yup
  .object({
    formType: yup
      .string()
      .oneOf(["table", "chart"], "Form type must be 'table'")
      .required("Form type is required"),

    startDate: yup.string().when(["dateRange", "formType"], (dateRange, formType, schema) => {
      if (formType === "table" && dateRange === "Custom") {
        return schema
          .required("Start Date is required for Custom Date Range")
          .test("is-valid-date", "Start Date must be a valid ISO date", (value) =>
            moment(value, moment.ISO_8601, true).isValid()
          )
          .test(
            "min-date",
            `Start Date cannot be earlier than ${moment(MIN_DATE).format("YYYY-MM-DD")}`,
            (value) => moment(value).isSameOrAfter(moment(MIN_DATE))
          );
      }
      return schema;
    }),

    endDate: yup.string().when(["dateRange", "formType"], (dateRange, formType, schema) => {
      if (formType === "table" && dateRange === "Custom") {
        return schema
          .required("End Date is required for Custom Date Range")
          .test("is-valid-date", "End Date must be a valid ISO date", (value) =>
            moment(value, moment.ISO_8601, true).isValid()
          )
          .test(
            "max-date",
            `End Date cannot be later than ${moment(MAX_DATE).format("YYYY-MM-DD")}`,
            (value) => moment(value).isSameOrBefore(moment(MAX_DATE))
          )
          .test("is-after-startDate", "End Date must be after Start Date", function (value) {
            const { startDate } = this.parent;
            return moment(value).isAfter(moment(startDate));
          });
      }
      return schema;
    }),

    tableColumns: yup
      .array()
      .of(yup.string())
      .when("formType", {
        is: "table",
        then: yup
          .array()
          .of(yup.string())
          .min(1, "At least one column must be selected for Table reports")
          .required(),
      }),

    xAxis: yup
      .string()
      .nullable()
      .when("formType", {
        is: "chart",
        then: yup.string().nullable().required("X-Axis is required for Chart reports"),
      }),

    yAxis: yup
      .string()
      .nullable()
      .when("formType", {
        is: "chart",
        then: yup.string().nullable().required("Y-Axis is required for Chart reports"),
      }),

    groupBy: yup
      .string()
      .nullable()
      .when("formType", {
        is: "chart",
        then: yup.string().nullable().required("Group By is required for Chart reports"),
      }),

    chartType: yup
      .string()
      .nullable()
      .when("formType", {
        is: "chart",
        then: yup.string().nullable().required("Chart Type is required for Chart reports"),
      }),
  })
  .test("field-validation", null, function (values) {
    const { formType, sortBy, order, dateField, dateRange } = values;

    if (formType === "table") {
      if (sortBy && !order) {
        return this.createError({
          path: "order",
          message: "Order is required when Sort By is selected",
        });
      }

      if (order && !sortBy) {
        return this.createError({
          path: "sortBy",
          message: "Sort By is required when Order is selected",
        });
      }

      if (!dateField && dateRange) {
        return this.createError({
          path: "dateField",
          message: "Date Field is required when Date Range is selected",
        });
      }

      if (dateField && !dateRange) {
        return this.createError({
          path: "dateRange",
          message: "Date Range is required when Date Field is selected",
        });
      }
    }

    return true;
  });
