import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppContextController } from "context/AppContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import updateJob from "layouts/pages/jobs/actions/updateJob";
import updateCompanyJob from "layouts/pages/companyjobs/actions/updateJob";

import { getDirtyFields } from "utils/helpers/formHelpers";

import { isEmpty } from "lodash";
import { Autocomplete, Grid, Icon, IconButton, Stack, Switch } from "@mui/material";
import { AddCircleRounded, ContentCopy, Settings } from "@mui/icons-material";
import { cardStyles as styles } from "layouts/pages/customers/styles";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import CustomTextField from "components/Form/CustomTextField";
import DataList from "components/DataList";
import PanelActions from "components/PanelActions";
import CustomCard from "components/CustomCard";
import CopyJobURLModal from "layouts/pages/jobs/components/CopyJobURLModal";
import ReminderToSaveChangesModal from "layouts/pages/events/components/EventsInformation/ReminderToSaveChangesModal";
import NotificationRecipientsModal from "layouts/pages/jobs/components/NotificationRecipientsModal";
import ConfirmPanelChanges from "components/ConfirmPanelChanges";
import fetchUsers from "layouts/pages/profile/actions/fetchUsers";
import useCompanyStore from "stores/useCompanyStore";
import useDropdowns from "hooks/useDropdowns";
import { COMPANY_ENTITIES } from "utils/constants/entityOptions";

const { GEO_PREFERENCE_ENTITY_NAME } = COMPANY_ENTITIES;

const CompanyJobsSettings = ({ isVenue = false }) => {
  const { currentJob, setCurrentJob, isMobile, setCurrentFormDirty, changePanel } =
    useAppContextController();
  const { currentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
  }));
  const { dropdowns: geoPreferences } = useDropdowns({ entity: GEO_PREFERENCE_ENTITY_NAME });
  const [copyJobURLModalOpen, setCopyJobURLModalOpen] = useState(false);
  const [reminderToSaveChangesModalOpen, setReminderToSaveChangesModalOpen] = useState(false);
  const [notificationRecipientsModalOpen, setNotificationRecipientsModalOpen] = useState(false);
  const [usersNames, setUsersNames] = useState();
  const options = { fetchAll: true };
  const allOptionsUsers = { ...options, filters: { userType: "Master;Admin" } };
  const { data: users, isLoading: isLoadingUsers } = useQuery(["users", allOptionsUsers], () =>
    fetchUsers(allOptionsUsers)
  );

  useEffect(() => {
    if (users?.data) {
      setUsersNames(
        users?.data
          ?.filter((item) => {
            return ["Active"].includes(item.status) && ["Employee"].includes(item.spStatus);
          })
          .map((user) => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
          }))
      );
    }
  }, [users]);

  const approvingManagerOptions = useMemo(() => {
    return (
      currentCompany?.contacts
        ?.map((user) => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
        }))
        ?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []
    );
  }, [currentCompany?.contacts]);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    reset,
    trigger,
    formState: { isDirty, dirtyFields },
  } = useForm();

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync: updateJobMutation } = useMutation(isVenue ? updateJob : updateCompanyJob);
  const idName = isVenue ? "_id" : "jobId";

  const handleUpdateJob = (updatedValues) => {
    updateJobMutation(
      { [idName]: currentJob?._id, data: updatedValues },
      {
        onSuccess: async (res) => {
          queryClient.invalidateQueries("company_jobs_table");
          queryClient.invalidateQueries("jobs");
          queryClient.invalidateQueries("useBadgeValues");
          setCurrentJob({ ...currentJob, ...updatedValues });
          enqueueSnackbar("Job has been updated.", { variant: "success" });
        },
        onError: (err) => console.log(err),
      }
    );
  };

  const onSubmit = (data) => {
    const temp = getDirtyFields(data, dirtyFields);
    handleUpdateJob({ ...temp });
  };

  const handleCancelChanges = () => reset(currentJob);

  const handleConfirmChanges = async () => {
    const isValid = await trigger();
    if (isValid) {
      handleSubmit(onSubmit)();
      changePanel();
      setCurrentFormDirty(false);
    }
  };

  const removeRecipient = (idx) => {
    const temporaryRecipients = getValues()?.notificationRecipients;
    temporaryRecipients.splice(idx, 1);
    setValue("notificationRecipients", temporaryRecipients);
    setReminderToSaveChangesModalOpen(true);
  };

  const addNotificationRecipients = () => setNotificationRecipientsModalOpen(true);

  const notificationRecipientsRenderRow = (row, idx) => {
    return (
      <>
        <Stack
          display="flex"
          direction="row"
          justifyContent="space-between"
          onClick={() => {
            setNotificationRecipientsModalOpen(true);
          }}
        >
          <Grid container>
            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>{row.fullName}</MDTypography>
            </Grid>

            <Grid item sm={5.5}>
              <MDTypography sx={{ fontSize: "0.9rem" }}>E-Mail: {row.email}</MDTypography>
            </Grid>

            <Grid item sm={1}>
              <MDBox textAlign="right">
                <Icon
                  color="error"
                  fontSize="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeRecipient(idx);
                  }}
                >
                  cancel
                </Icon>
              </MDBox>
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  };

  useEffect(() => {
    reset(currentJob);
  }, [currentJob]);

  useEffect(() => {
    setCurrentFormDirty(isDirty);
  }, [isDirty]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CustomCard
        icon={<Settings color="white" />}
        iconContainerSx={{ backgroundColor: "info" }}
        cardTitle={`Settings: ${currentJob?.title}`}
        cardSx={{ overflow: "visible!important", borderRadius: 0, height: 850 }}
        cardActions={isDirty && <PanelActions onCancel={handleCancelChanges} />}
        actionsSx={isMobile ? {} : { mt: -4 }}
      >
        <MDBox sx={{ ...styles.container, height: 614 }}>
          <Grid container spacing={3} pt={2}>
            {!isVenue && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="approvingManager"
                  control={control}
                  defaultValue={currentJob?.approvingManager}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      key={`autocomplete_approvingManager_${currentJob?.id}`}
                      options={approvingManagerOptions || []}
                      autoSelect
                      freeSolo
                      name="approvingManager"
                      defaultValue={currentJob?.approvingManager}
                      value={field.value || ""}
                      onChange={(e, v) => {
                        setValue("approvingManager", v, { shouldDirty: true });
                      }}
                      onBlur={() => trigger("approvingManager")}
                      renderInput={(params) => (
                        <CustomTextField
                          key={`approvingManager_${currentJob?.id}`}
                          {...params}
                          showError
                          errorMessage={error?.message}
                          type="text"
                          label="Approving Manager"
                          isShrink={!isEmpty(getValues("approvingManager"))}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            {!isVenue && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="recruiter"
                  control={control}
                  defaultValue={currentJob?.recruiter}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      key={`autocomplete_recruiter_${currentJob?.id}`}
                      options={
                        usersNames?.sort((a, b) => (a.firstName > b.firstName ? 1 : -1)) || []
                      }
                      autoSelect
                      freeSolo
                      name="recruiter"
                      defaultValue={currentJob?.recruiter}
                      value={field.value || ""}
                      onChange={(e, v) => {
                        setValue("recruiter", v, { shouldDirty: true });
                      }}
                      onBlur={() => trigger("recruiter")}
                      renderInput={(params) => (
                        <CustomTextField
                          key={`recruiter_${currentJob?.id}`}
                          {...params}
                          type="text"
                          showError
                          errorMessage={error?.message}
                          label="Recruiter"
                          isShrink={!isEmpty(getValues("recruiter"))}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            {!isVenue && (
              <Grid item xs={12} sm={6}>
                <Controller
                  name="geoPreference"
                  control={control}
                  defaultValue={currentJob?.geoPreference}
                  render={({ field, fieldState: { error } }) => (
                    <Autocomplete
                      key={`autocomplete_geoPreference_${currentJob?.id}`}
                      options={geoPreferences || []}
                      autoSelect
                      name="geoPreference"
                      defaultValue={currentJob?.geoPreference}
                      value={field.value || ""}
                      onChange={(e, v) => {
                        setValue("geoPreference", v, { shouldDirty: true });
                      }}
                      onBlur={() => trigger("geoPreference")}
                      renderInput={(params) => (
                        <CustomTextField
                          key={`geoPreference_${currentJob?.id}`}
                          {...params}
                          type="text"
                          showError
                          errorMessage={error?.message}
                          label="Default Job Search Geo Preference"
                          isShrink={!isEmpty(getValues("geoPreference"))}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Job Logo</MDTypography>
                    <Controller
                      name="jobLogo"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          color="info"
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                {!isVenue && (
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Company Banner</MDTypography>
                      <Controller
                        name="companyThumb"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                )}
                {isVenue && (
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Venue Banner</MDTypography>
                      <Controller
                        name="venueThumb"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Hide this Job</MDTypography>
                    <Controller
                      name="hideThisJob"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                {!isVenue && (
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Show Customer</MDTypography>
                      <Controller
                        name="showCustomer"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                )}
                {isVenue && (
                  <Grid item xs={12} sm={6}>
                    <Stack
                      my={-1.5}
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <MDTypography variant="body2">Posted on Indeed</MDTypography>
                      <Controller
                        name="postToIndeed"
                        control={control}
                        render={({ field }) => (
                          <Switch
                            checked={field.value === "Yes"}
                            onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Google Map</MDTypography>
                    <Controller
                      name="googleMap"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          disabled
                          inputProps={{ tabIndex: -1 }}
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Copy Job Sites Url</MDTypography>
                    <IconButton
                      color="info"
                      onClick={() => {
                        setCopyJobURLModalOpen(true);
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack
                    my={-1.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <MDTypography variant="body2">Shift Job</MDTypography>
                    <Controller
                      name="shiftJob"
                      control={control}
                      render={({ field }) => (
                        <Switch
                          color="info"
                          checked={field.value === "Yes"}
                          onChange={(e) => field.onChange(e.target.checked ? "Yes" : "No")}
                        />
                      )}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <MDBox mt={1} display="flex" justifyContent="space-between" alignItems="center">
                <MDTypography component="label" variant="body2">
                  Notification Recipients
                </MDTypography>
                <IconButton color="info" onClick={addNotificationRecipients}>
                  <AddCircleRounded />
                </IconButton>
              </MDBox>
              <Controller
                name="notificationRecipients"
                control={control}
                render={({ field }) => (
                  <DataList
                    renderRow={notificationRecipientsRenderRow}
                    data={getValues().notificationRecipients === undefined ? [] : field.value}
                    greybar
                    divider
                    scrollY
                    tableHeight={170}
                    height={200}
                    isLoading={false}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      </CustomCard>
      <CopyJobURLModal
        jobSlug={currentJob?.jobSlug}
        open={copyJobURLModalOpen}
        setOpen={setCopyJobURLModalOpen}
      />
      <NotificationRecipientsModal
        open={notificationRecipientsModalOpen}
        setOpen={setNotificationRecipientsModalOpen}
        NotificationRecipientsArray={getValues().notificationRecipients || []}
        setValue={setValue}
        setReminderToSaveChangesModalOpen={setReminderToSaveChangesModalOpen}
      />
      <ReminderToSaveChangesModal
        open={reminderToSaveChangesModalOpen}
        setOpen={setReminderToSaveChangesModalOpen}
      />
      <ConfirmPanelChanges onConfirm={handleConfirmChanges} />
    </form>
  );
};

export default CompanyJobsSettings;
