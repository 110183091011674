import { isEmpty } from "lodash";
import * as yup from "yup";
import partners from "../assets/partners.json";

const partnerList = partners.map((item) => item.partnerName);

export const VENUE_APPLICANT_DEFAULT_VALUES = (isApplicant = true) => ({
  firstName: "",
  lastName: "",
  rank: "",
  primaryPosition: "",
  status: isApplicant ? "Applicant" : "Employee",
  applicantStatus: isApplicant ? "New" : "Pre-Hire",
  employmentStatus: "Active",
  systemSource: "",
  partnerSlug: "",
  phone: "",
  altPhone: "",
  email: "",
  altEmail: "",
  hireDate: "",
  address: "",
  birthDate: "",
  city: "",
  state: null,
  zip: "",
  recordLocked: "No",
  licenses: [],
  attachments: [],
  notes: [],
  tags: [],
});

export const COMPANY_APPLICANT_DEFAULT_VALUES = (isApplicant = true) => ({
  firstName: "",
  lastName: "",
  status: isApplicant ? "Applicant" : "Employee",
  applicantStatus: isApplicant ? "New" : "Pre-Hire",
  employmentStatus: "Inactive",
  systemSource: "",
  partnerSlug: "",
  phone: "",
  altPhone: "",
  email: "",
  altEmail: "",
  hireDate: "",
  address: "",
  birthDate: "",
  city: "",
  state: null,
  zip: "",
  recordFlagged: "No",
  attachments: [],
  notes: [],
  socialSecurity: "",
  isAvailable: "No",
  tags: [],
});

export const applicantsSchema = (
  isCompany,
  dropdowns,
  applicantId,
  parent,
  isEdit,
  payFrequencyValues
) =>
  yup.object().shape({
    firstName: yup
      .string()
      .required("First name is required")
      .matches(/^[A-Za-z\s.,]*$/, "First Name contains invalid characters."),
    lastName: yup
      .string()
      .required("Last name is required")
      .matches(/^[A-Za-z\s.,]*$/, "Last Name contains invalid characters."),
    userId: yup.string(),
    primaryPosition: yup
      .string()
      .nullable()
      .test({
        name: "validate-primaryPosition",
        test: (value, ctx) => {
          if (!value) return true;
          if (!dropdowns?.primaryPositions.includes(value) && !isCompany) {
            return ctx.createError({ message: "Insert a primary position" });
          }
          return true;
        },
      }),
    status: yup
      .string()
      .nullable()
      .test({
        name: "validate-status",
        test: (value, ctx) => {
          if (value) {
            if (
              parent === "Employee"
                ? !dropdowns?.employeeStatusField?.includes(value)
                : !dropdowns?.applicantStatusField?.includes(value) || isEmpty(value)
            ) {
              return ctx.createError({ message: "Insert a valid Status" });
            }
          }

          return true;
        },
      }),
    referralSource: yup
      .string()
      .nullable()
      .test({
        name: "validate-referralSource",
        test: (value, ctx) => {
          if (!value) return true;
          if (!dropdowns?.referralSources?.includes(value)) {
            return ctx.createError({ message: "Select a valid Applicant Source" });
          }
          return true;
        },
      }),
    partnerSlug: yup
      .string()
      .nullable()
      .test({
        name: "validate-partnerList",
        test: (value, ctx) => {
          if (!value) return true;
          if (!partnerList.includes(value)) {
            return ctx.createError({ message: "Select a Valid Partner" });
          }
          return true;
        },
      }),
    // employeeID: yup.string().test({
    //   name: "validate-employeeId",
    //   test: (value, ctx) => {
    //     if (ctx.parent.status === "Employee" && !value) {
    //       return ctx.createError({ message: "Employee Id is required" });
    //     }
    //     return true;
    //   },
    // }),
    rank: yup.mixed(),
    employmentStatus: yup
      .string()
      .nullable()
      .test({
        name: "validate-employmentStatus",
        test: (value, ctx) => {
          if (isCompany) {
            return true;
          }
          if (!dropdowns?.employmentStatusesField.includes(value)) {
            return ctx.createError({
              message: "Insert a valid Employment Status",
            });
          }
          return true;
        },
      }),
    applicantStatus: yup
      .string()
      .nullable()
      .test({
        name: "validate-applicantStatus",
        test: (value, ctx) => {
          if (isCompany) {
            return true;
          }
          if (!dropdowns?.applicantStagesField.includes(value)) {
            return ctx.createError({
              message: "Insert a valid Applicant Status",
            });
          }
          return true;
        },
      }),
    phone: yup.string().test({
      name: "validate-phone",
      test: (value, ctx) => {
        const trimValueLength = value.replace(/\D/g, "").length;

        if (trimValueLength === 10 || trimValueLength === 0) {
          return true;
        }
        return ctx.createError({ message: "Phone number must be 10 digits" });
      },
    }),
    altPhone: yup.string().test({
      name: "validate-phone",
      test: (value, ctx) => {
        if (value) {
          const trimValueLength = value.replace(/\D/g, "").length;

          if (trimValueLength === 10 || trimValueLength === 0 || !value) {
            return true;
          }
          return ctx.createError({ message: "Phone number must be 10 digits" });
        }
        return true;
      },
    }),
    email: yup.string().test({
      name: "validate-email",
      test: async (value, ctx) => {
        if (!value && !isEdit) return ctx.createError({ message: "Email is required" });
        if (/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value) || !value) {
          return true;
        }
        return ctx.createError({ message: "Invalid Email Address" });
      },
    }),
    altEmail: yup.string().test({
      name: "validate-altemail",
      test: (value, ctx) => {
        if (/^[\w-.]+@([\w-]+.)+[\w-]{2,4}$/.test(value) || !value) {
          return true;
        }
        return ctx.createError({ message: "Invalid Email Address" });
      },
    }),
    hireDate: yup.mixed(),
    address: yup.string().nullable(),
    birthDate: yup.mixed(),
    city: yup.string(),
    state: yup
      .string()
      .nullable()
      .test({
        name: "validate-state",
        test: (value, ctx) => {
          if (dropdowns?.stateCodes?.includes(value) || isEmpty(value)) return true;
          return ctx.createError({ message: "Insert a valid state" });
        },
      }),
    zip: yup.string().test({
      name: "validate-zip",
      test: (value, ctx) => {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(value) || !value) return true;
        return ctx.createError({ message: "Your zip number is invalid" });
      },
    }),
    recordLocked: yup.string(),
    securityLicense: yup.mixed().nullable(),
    securityLicenseExpDate: yup.mixed().nullable(),
    gamingLicense: yup.mixed().nullable(),
    tabcLicense: yup.mixed().nullable(),
    tabcLicenseExpDate: yup.mixed().nullable(),
    thirtyDayAlert: yup.string(),
    tags: yup.array().nullable(),
    socialSecurity: yup
      .string()
      .nullable()
      .test({
        name: "test-socialSecurity-length",
        test: (value, ctx) => {
          if (value && isCompany) {
            const trimValueLength = value.replace(/-/g, "").replace(/_/g, "").length;
            if (trimValueLength === 9) {
              return true;
            }
            return ctx.createError({ message: "Social Security number must be 9 digits" });
          }
          return true;
        },
      }),
    payFrequency: yup
      .string()
      .nullable()
      .trim()
      .test({
        name: "payFrequency exist",
        test(value, ctx) {
          if (value && !payFrequencyValues?.find((item) => item?.value === value)) {
            return ctx.createError({ message: "Insert a valid pay frequency" });
          }
          return true;
        },
      })
      .default(""),
  });
