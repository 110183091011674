import { useNavigate } from "react-router-dom";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import ApplicantMoreButton from "components/ApplicantMoreButton";

const ApplicantsTableActions = ({
  id,
  type,
  row,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
  parentTable,
  onRefresh,
}) => {
  const { company } = useAppContextController();
  const navigate = useNavigate();
  const route = type === "Staffing" ? "employees" : "applicants";
  const actionroute = type === "Staffing" ? "employee" : "applicant";
  const onClickHandler = (action, e) => {
    e.stopPropagation();
    console.log(action);
    if (action === "applicantmail" || action === "employeemail") {
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      navigate(`/${route}/${id}/action/${action}`);
    }
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler(`${actionroute}info?backTo=${parentTable}`, e)}
        tooltip="Applicant Info"
      />
      {company?.companyType === "Venue" ? (
        <TableAction
          color="info"
          icon="stadium"
          clickAction={(e) => onClickHandler(`${actionroute}venues`, e)}
          tooltip="Applicant Venues"
        />
      ) : (
        <TableAction
          color="info"
          icon="business"
          clickAction={(e) => onClickHandler(`${actionroute}customers`, e)}
          tooltip="Applicant Customers"
        />
      )}
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler(`${actionroute}jobs`, e)}
        tooltip="Applicant Jobs"
      />
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler(`${actionroute}events`, e)}
        tooltip="Applicant Event"
      />
      <TableAction
        color="error"
        icon="mail"
        clickAction={(e) => onClickHandler(`${actionroute}mail`, e)}
        tooltip="Applicant Mail"
      />
      <ApplicantMoreButton applicant={row} onSuccess={onRefresh} />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler(`${actionroute}all`, e)}
        tooltip="All Applicant Information"
      /> */}
    </span>
  );
};

export default ApplicantsTableActions;

ApplicantsTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
ApplicantsTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
