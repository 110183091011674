import { FormControlLabel, Switch, Typography } from "@mui/material";
import FilterButtons from "components/FilterButtons";
import { PAYROLL_EXPORT_OPTIONS } from "components/FilterButtons/options";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { cloneDeep, range } from "lodash";
import { useEffect, useState } from "react";
import usePayrollExportStore from "stores/usePayrollExportStore";
import { timeSheetFields, eventRosterFields, eventFields } from "./fields";

const ExportFields = ({ exportType, setExportType }) => {
  const [filter, setFilter] = useState({ dataFields: "eventRoster" });

  const [selectAll, toggleSelectAll] = useState(true);
  const { setFields, fields } = usePayrollExportStore((state) => ({
    setFields: state.setFields,
    fields: state.fields,
  }));

  const handleChange = (e, name) => {
    toggleSelectAll(
      Object.values({ ...fields?.[filter?.dataFields], [name]: e.target.checked }).every((v) => v)
    );
    setFields({
      ...fields,
      [filter?.dataFields]: { ...fields?.[filter?.dataFields], [name]: e.target.checked },
    });
  };

  useEffect(() => {
    if (fields && filter && filter.dataFields && fields[filter.dataFields]) {
      setExportType(filter.dataFields);
      toggleSelectAll(Object.values(fields[filter.dataFields]).every((v) => v));
    }
  }, [fields, filter?.dataFields]);

  useEffect(() => {
    setFields({
      eventRoster: eventRosterFields.reduce((a, v) => ({ ...a, [v.name]: true }), {}),
      timeSheet: timeSheetFields.reduce((a, v) => ({ ...a, [v.name]: true }), {}),
      events: eventFields.reduce((a, v) => ({ ...a, [v.name]: true }), {}),
    });
  }, [setFields]);

  let activeFields;
  switch (filter?.dataFields) {
    case "eventRoster":
      activeFields = eventRosterFields;
      break;
    case "timeSheet":
      activeFields = timeSheetFields;
      break;
    case "events":
      activeFields = eventFields;
      break;
    default:
      break;
  }

  const itemsPerColumn = Math.ceil((activeFields?.length ?? 0) / 3)

  return (
    <FlexWrapper justifyContent="flex-start" flexDirection="column" alignItems="start" gap="10px">
      <Typography>Select Export Fields</Typography>
      <FilterButtons
        filter={filter}
        setFilter={setFilter}
        name="dataFields"
        options={PAYROLL_EXPORT_OPTIONS}
      />
      <FlexWrapper flexWrap="nowrap" alignItems="flex-start">
        {range(3).map((v) => {
          return (
            <FlexWrapper flexDirection="column" alignItems="start" justifyContent="start">
              {activeFields.slice(v * itemsPerColumn, v * itemsPerColumn + itemsPerColumn).map((value) => {
                return (
                  <FormControlLabel
                    key={value.name}
                    control={
                      <Switch
                        checked={!!fields?.[filter?.dataFields]?.[value.name]}
                        onChange={(e) => handleChange(e, value.name)}
                      />
                    }
                    label={value.label}
                  />
                );
              })}
            </FlexWrapper>
          );
        })}
      </FlexWrapper>
      <FormControlLabel
        control={
          <Switch
            checked={selectAll}
            onChange={(e) => {
              const updatedFields = cloneDeep(fields?.[filter?.dataFields]);
              Object.keys(fields?.[filter?.dataFields]).forEach((item) => {
                updatedFields[item] = e.target.checked;
              });
              setFields({ ...fields, [filter?.dataFields]: updatedFields });
              toggleSelectAll(e.target.checked);
            }}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Select All"
        sx={{ alignSelf: "end" }}
      />
    </FlexWrapper>
  );
};

export default ExportFields;
