/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useMapsLibrary } from "@vis.gl/react-google-maps";
import createCompany from "api/companies/createCompany";
import updateCompany from "api/companies/updateCompany";
import FormTextEditor from "components/FormTextEditor";
import FlexWrapper from "components/Wrapper/FlexWrapper";
import { IMG_SERVER } from "config";
import { useAppContextController } from "context/AppContext";
import { createCompanyInfoSchema, DEFAULT_CUSTOMER } from "data/companyInfo";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import useCompanyStore from "stores/useCompanyStore";
import TagList from "components/TagList";
import {
  formatSlug,
  getDirtyFields,
  integratePrimaryToContacts,
  integratePrimaryToLocations,
} from "utils/helpers/formHelpers";
import CompanyFormActions from "layouts/pages/companies/components/CompanyInfoPanel/CompanyFormActions";
import CompanyPreview from "layouts/pages/companies/components/CompanyPreview";
import CompanyInformationSection from "layouts/pages/companies/components/CompanyInformationSection";
import CompanyHeader from "layouts/pages/companies/components/CompanyInfoPanel/CompanyHeader";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const locFields = ["address", "city", "state", "zip"];

const CustomerForm = ({
  onSubmit,
  setRefreshCompanies,
  panelOptions,
  onPanelSelect,
  selectedPanel,
}) => {
  const { setCompanyFormState, companyFormState, setCurrentCompany, currentCompany } =
    useCompanyStore((state) => ({
      setCompanyFormState: state.setCompanyFormState,
      companyFormState: state.companyFormState,
      setCurrentCompany: state.setCurrentCompany,
      currentCompany: state.currentCompany,
    }));

  const { company, currentLoggedUser } = useAppContextController();

  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [companyStatus, setCompanyStatus] = useState("Active");

  const isEdit = !!currentCompany?._id;
  const validationSchema = createCompanyInfoSchema(companyStatus, isEdit);
  const resolver = useYupValidationResolver(validationSchema);

  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewContent, setPreviewContent] = useState(null);
  const [geocoder, setGeocoder] = useState(null);

  const geocoding = useMapsLibrary("geocoding");

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    watch,
    reset,
    setError,
    trigger,
    formState,
  } = useForm({ resolver, defaultValues: currentCompany });
  const { errors, isDirty, dirtyFields } = formState;

  const { mutateAsync: updateCompanyMutation, isLoading } = useMutation(updateCompany);
  const { mutateAsync: createCompanyMutation, isLoading: isLoadingCreate } =
    useMutation(createCompany);

  const watchStatus = watch("status", companyStatus); // Watching the 'status' field for changes

  useEffect(() => {
    setCompanyStatus(watchStatus); // Update the state whenever the 'status' changes
  }, [watchStatus]);

  const openPreviewModal = (content) => {
    setPreviewContent(content);
    setPreviewOpen(!isPreviewOpen);
  };

  const geocode = (request) => {
    return geocoder
      .geocode(request)
      .then((result) => {
        const { results } = result;
        return results;
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(`Geocode error: ${e}`);
      });
  };

  const handleGetCoordinates = async ({ address, city, state, zip }) => {
    let coordinates;

    if (address && city && state && zip) {
      const fullAddress = [address, city, state, zip].toString();
      const res = await geocode({ address: fullAddress });
      const loc = res?.[0]?.geometry?.location;
      coordinates = [loc?.lng(), loc?.lat()];
    }

    return coordinates;
  };

  const handleGetNewLoc = (slug, coordinates) => {
    const locationName = "Primary Location";
    const geocoordinates = {
      type: "Point",
      geoFenceRadius: 100,
      coordinates: coordinates || [0, 0],
    };

    return {
      locationName,
      primaryLocation: "Yes",
      locationSlug: formatSlug(slug || "", locationName),
      geocoordinates,
    };
  };

  const updateCompanyHandler = async (values) => {
    let data = getDirtyFields(values, dirtyFields);

    if (data?.contact) {
      const { contact } = data;
      data = integratePrimaryToContacts(data, contact, currentCompany?.contacts);
    }

    const { address, city, state, zip } = data;

    const dirtyTest = Object.keys(dirtyFields).some((a) => locFields.includes(a));

    if (dirtyTest) {
      const commonProps = {
        ...(dirtyFields.address && { address }),
        ...(dirtyFields.city && { city }),
        ...(dirtyFields.state && { state }),
        ...(dirtyFields.zip && { zip }),
      };

      const currentLoc = {
        address: currentCompany?.address,
        city: currentCompany?.city,
        state: currentCompany?.state,
        zip: currentCompany?.zip,
        ...commonProps,
      };

      const coordinates = await handleGetCoordinates(currentLoc);

      const tempLoc = {
        ...currentLoc,
        ...(coordinates && { latitude: coordinates?.[1], longitude: coordinates?.[0] }),
      };

      const defaultNewLoc = handleGetNewLoc(currentCompany?.slug, coordinates);

      data = integratePrimaryToLocations(
        data,
        tempLoc,
        defaultNewLoc,
        currentCompany?.location,
        coordinates,
        currentCompany?.locations
      );
    }

    updateCompanyMutation(
      {
        companyId: currentCompany?._id,
        body: data,
      },
      {
        onSuccess: (res) => {
          const item = res.updatedItem ? { ...res.updatedItem } : { ...currentCompany, ...data };
          setRefreshCompanies((prev) => !prev);
          setCurrentCompany(item);
          enqueueSnackbar("Company has been updated.", { variant: "success" });
          reset(item, { keepValues: true });
        },
      }
    );
  };

  const createCompanyHandler = async (values) => {
    const { contact, address, city, state, zip } = values;
    const contacts = [{ ...contact, primaryContact: "Yes" }];
    const initLoc = { type: "Point", geoFenceRadius: 100, coordinates: [0, 0] };
    let location = { ...initLoc };
    let locations = [];

    if (address || city || state || zip) {
      const coordinates = (await handleGetCoordinates(values)) || [0, 0];

      const commonProps = {
        address,
        city,
        state,
        zip,
        radius: 100,
        graceDistanceFeet: 100,
        latitude: coordinates?.[1] || 0,
        longitude: coordinates?.[0] || 0,
      };

      locations = [
        {
          ...commonProps,
          ...handleGetNewLoc(values?.slug, coordinates),
        },
      ];

      location = { ...initLoc, coordinates, ...commonProps };
    }

    const data = { ...values, contacts, location, locations };
    data.createAgent = currentLoggedUser._id;
    data.imageUrl = isEmpty(company)
      ? `${IMG_SERVER}/${values.slug}`
      : `${IMG_SERVER}/${company.slug}`;

    await createCompanyMutation(data, {
      onSuccess: (res) => {
        if (res.acknowledged) {
          const item = res.insertedItem
            ? { ...res.insertedItem }
            : { _id: res.insertedId, ...data };
          setRefreshCompanies((prev) => !prev);
          onSubmit?.();
          enqueueSnackbar("Customer has been created!", { variant: "success" });
          setCurrentCompany(item);
          // Uncomment to make the new item the only one in the list
          // navigate(`/customers/${data.slug}/action/customerinfo`);
          navigate(`/customers/${data.slug}`);
        }
      },
      onError: (err) => enqueueSnackbar("Something went wrong!", { variant: "error" }),
    });
  };

  useEffect(() => {
    if (!isEmpty(currentCompany)) {
      delete currentCompany?.longitude;
      delete currentCompany?.latitude;
      delete currentCompany?.radius;
    }
    reset(isEdit ? currentCompany : DEFAULT_CUSTOMER);
  }, [currentCompany, isEdit]);

  useEffect(() => {
    setCompanyFormState({ ...companyFormState, isDirty, dirtyFields });
  }, [isDirty, dirtyFields]);

  // not recommended to use, only temporary to fill up locations
  useEffect(() => {
    if (currentCompany && (!currentCompany?.locations || isEmpty(currentCompany?.locations))) {
      const { address, city, state, zip } = currentCompany;
      if (address || city || state || zip) {
        const locations = [
          {
            locationName: "Primary Location",
            primaryLocation: "Yes",
            ...(address && { address }),
            ...(city && { city }),
            ...(state && { state }),
            ...(zip && { zip }),
          },
        ];
        updateCompanyMutation(
          {
            companyId: currentCompany?._id,
            body: { locations },
          },
          {
            onSuccess: () => {
              setRefreshCompanies((prev) => !prev);
              setCurrentCompany({ ...currentCompany, locations });
              reset({ ...currentCompany, locations }, { keepValues: true });
            },
          }
        );
      }
    }
  }, [currentCompany]);

  // useEffect(() => {
  //   if (watchStatus !== "Prospect" && selectedPanel === "sales") {
  //     onPanelSelect("activities");
  //   }
  // }, [watchStatus]);

  useEffect(() => {
    if (!geocoding) return;
    setGeocoder(new geocoding.Geocoder());
  }, [geocoding]);

  return (
    <>
      <FlexWrapper
        maxWidth="100%"
        justifyContent="flex-end"
        alignItems="center"
        minHeight={38}
        mt={-5}
        pr={1}
      >
        <CompanyFormActions
          sourceComponent="Customer"
          isLoading={isLoading}
          isLoadingCreate={isLoadingCreate}
          isDirty={isDirty}
          dirtyFields={dirtyFields}
          resetChanges={() => {
            reset(currentCompany);
          }}
          currentCompany={currentCompany}
          onPreview={() => openPreviewModal("all")}
        />
      </FlexWrapper>
      <Grid container sx={{ px: "1.5rem" }} alignItems="center">
        <Grid item xs={12} sm={7}>
          <CompanyHeader sourceComponent="Customer" />
        </Grid>
        <Grid item xs={12} sm={5}>
          <MDBox display="flex" justifyContent="flex-end">
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              {panelOptions.map((tab) => (
                <Tooltip title={tab?.title} key={tab?.title}>
                  <MDBox variant="gradient" borderRadius="50%" shadow={tab.hasShadow} m={1}>
                    <IconButton
                      color={tab.color}
                      aria-label={tab.ariaLabel}
                      onClick={tab.fn}
                      disabled={!isEdit} // || (tab.title === "Sales" && watchStatus !== "Prospect")}
                    >
                      {tab.icon}
                    </IconButton>
                  </MDBox>
                </Tooltip>
              ))}
            </Stack>
          </MDBox>
        </Grid>
      </Grid>

      <Box sx={{ height: 660, overflowY: "auto", p: "1.5rem", pt: 1 }}>
        <form
          onSubmit={handleSubmit(isEdit ? updateCompanyHandler : createCompanyHandler)}
          id="company-form"
          style={{ width: "100%" }}
        >
          <MDTypography variant="p" fontSize="18px" color="info" fontWeight="bold">
            Customer Information
          </MDTypography>
          <Grid container pt={3}>
            <Grid item xs={12}>
              <CompanyInformationSection
                control={control}
                setValue={setValue}
                isEdit={isEdit}
                selectedStatus={watchStatus}
                onBlur={() => trigger("accountManager")}
                watch={watch}
              />
              <FormTextEditor
                title="Customer Description"
                name="description"
                onClick={() => openPreviewModal("description")}
                control={control}
                height="15.025rem"
                sx={{ mt: 3 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="tags"
                control={control}
                render={({ field }) => (
                  <TagList
                    {...field}
                    value={getValues().tags || []}
                    setValue={setValue}
                    setError={setError}
                    clearErrors={clearErrors}
                    currentComponent={currentCompany}
                  />
                )}
              />
              {errors?.invalidTag && (
                <StyledTypographyError>{errors?.invalidTag?.message}</StyledTypographyError>
              )}
            </Grid>
          </Grid>
        </form>
        {isPreviewOpen && (
          <CompanyPreview
            open={isPreviewOpen}
            onClose={() => setPreviewOpen(!isPreviewOpen)}
            content={previewContent}
          />
        )}
      </Box>
    </>
  );
};

const StyledTypographyError = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  fontSize: "0.75rem",
}));

export default CustomerForm;
