import { useEffect, useState } from "react";
import { useAppContextController } from "context/AppContext";
import moment from "moment";
import GenericModal from "components/GenericModal";
import Box from "@mui/material/Box";
import MDTypography from "components/MDTypography";
import SendMessage from "components/SendMessage";
import AddANote from "components/AddANote";
import noteTypes from "assets/noteTypes.json";
import MDBox from "components/MDBox";
import postNote from "layouts/pages/applicants/actions/postNote";
import updateApplicant from "layouts/pages/applicants/actions/updateApplicant";
import useSessionAuth from "hooks/useSessionAuth";
import updatePartner from "layouts/pages/partners/actions/updatePartner";
import postNotePartner from "layouts/pages/partners/actions/postNotePartner";
import usePartnerLeaderChecker from "hooks/usePartnerLeaderChecker";
import { CircularProgress, Grid } from "@mui/material";

const EventPartnerActionModal = ({
  modalInfo,
  setModalInfo,
  open,
  setOpen,
  setToastAlert,
  toggleReadApps,
}) => {
  const { currentLoggedUser, currentEvent } = useAppContextController();
  const partnerId = modalInfo?.data?._id;
  const currentPartner = modalInfo?.data;
  const [text, setText] = useState(false);
  const [email, setEmail] = useState(true);
  const [system, setSystem] = useState(false);
  let modalBody;
  let header;
  let buttons;

  const { currentPartnerLeader, isLoadingPartnerLeader, checkPartnerLeader } =
    usePartnerLeaderChecker({ currentPartner });

  useEffect(() => {
    checkPartnerLeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartner, currentPartner?.leader?.email, currentPartner?.leader?.phone]);

  const saveChanges = (notes) => {
    const update = async () => {
      let res;
      await updatePartner({ id: partnerId, data: { notes } })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
          toggleReadApps();
          setModalInfo({ ...modalInfo, data: { ...modalInfo.data, notes } });
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
        });
      return res;
    };
    update();
  };

  const { logoutAuth0User } = useSessionAuth();

  const saveNote = (noteRec) => {
    // first, we need to add the note to partner notes
    const newNote = {
      type: noteRec.type,
      text: `<p><strong>${currentEvent?.venueSlug ? currentEvent.venueSlug.toUpperCase() : ""} - ${
        currentEvent?.eventName
      }</strong>:<br />${moment(new Date(currentEvent?.eventDate)).format(
        "ddd MM/DD/YYYY hh:mm a"
      )}:<br /><blockquote>${noteRec.text}</blockquote></p>`,
      firstName: currentLoggedUser?.firstName,
      lastName: currentLoggedUser?.lastName,
      userId: currentLoggedUser?._id,
    };

    if (currentEvent?.eventUrl) newNote.eventUrl = currentEvent.eventUrl;

    const updateNewNote = async () => {
      let res;
      await postNotePartner({ partnerId, data: newNote })
        .then((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: "Notes Have Been Successfully Updated",
            status: "success",
          });
          toggleReadApps();
        })
        .catch((response) => {
          res = response;
          setToastAlert({
            isShow: true,
            message: `Something went wrong! ${response}`,
            status: "error",
          });
          if (String(response).includes("401") || response?.response?.status === 401) {
            logoutAuth0User();
          }
        });
      return res;
    };

    return updateNewNote();
  };

  if (modalInfo.type === "partnerMail") {
    header = (
      <Box display="flex" key={`box_sendMessage_${modalInfo.data.id}`}>
        <MDBox sx={{ mt: 2, mb: 1, ml: 2 }}>
          {!isLoadingPartnerLeader && !currentPartnerLeader && (
            <MDTypography variant="h5" color="dark">
              No leader available
            </MDTypography>
          )}
          {!isLoadingPartnerLeader && !!currentPartnerLeader && (
            <>
              <MDTypography variant="h5" color="dark">
                Send Message to {currentPartnerLeader?.lastName} {currentPartnerLeader?.firstName}
              </MDTypography>
              {email && (
                <MDTypography variant="body2" color="info" fontWeight="bold">
                  {`E-mail: ${currentPartnerLeader?.email}`}
                </MDTypography>
              )}
              {text && (
                <MDTypography variant="body2" color="info" fontWeight="bold">
                  {`Phone: ${currentPartnerLeader?.phone}`}
                </MDTypography>
              )}
            </>
          )}
        </MDBox>
      </Box>
    );
    modalBody = (
      <Box>
        {isLoadingPartnerLeader && (
          <Grid container flex={1} justifyContent="center" pt={0} pb={2}>
            <CircularProgress color="info" />
          </Grid>
        )}
        {!isLoadingPartnerLeader && !!currentPartnerLeader && (
          <>
            <SendMessage
              recipient={currentPartnerLeader}
              setToastAlert={setToastAlert}
              setOpen={setOpen}
              text={text}
              setText={setText}
              email={email}
              setEmail={setEmail}
              system={system}
              setSystem={setSystem}
              applicant={currentPartnerLeader}
              eventId={currentEvent?._id}
              messageContext="Event"
            />
          </>
        )}
      </Box>
    );
  } else if (modalInfo.type === "partnerComment") {
    header = (
      <MDTypography
        variant="h5"
        color="dark"
        sx={{ mt: 2, mb: 1, ml: 2 }}
        key={`text_addNote_${modalInfo.data.id}`}
      >
        Add An Event Note For: {currentPartner?.name}
      </MDTypography>
    );
    modalBody = (
      <AddANote
        noteTypes={noteTypes}
        setOpen={setOpen}
        saveNote={saveNote}
        saveChanges={saveChanges}
        applicant={currentPartner}
        currentEvent={currentEvent}
      />
    );
  }

  // setModalBody(<div>{JSON.stringify(row)}</div>);

  return (
    <>
      <GenericModal
        open={open}
        setOpen={setOpen}
        body={modalBody}
        header={header}
        buttons={buttons}
      />
    </>
  );
};

export default EventPartnerActionModal;
