import { useEffect, useMemo, useState } from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import colors from "assets/theme/base/colors";
// import MDAvatar from "components/MDAvatar";
// import fetchUserById from "layouts/pages/users/actions/fetchUserById";
import fetchSingleUser from "layouts/pages/users/actions/fetchSingleStaffUser";
import { useAppContextController } from "context/AppContext";
import useSessionAuth from "hooks/useSessionAuth";

const useStyle = makeStyles({
  avatarContainer: {
    position: "relative",
    display: "inline-block", // Ensure the container is inline
  },
  avatarHover: {
    position: "absolute",
    left: "60px", // Position to the right of the original avatar
    top: 0,
    transform: "scale(2.0)",
    zIndex: 1000,
    transition: "transform 0.3s ease-in-out",
    transformOrigin: "left center", // Origin of the transformation
    visibility: "hidden", // Initially hidden
  },
  showOnHover: {
    "&:hover $avatarHover": {
      visibility: "visible", // Show on hover
    },
  },
});

const avatarSize = (size) => {
  switch (size) {
    case "lg":
      return { height: "75px", width: "75px" };
    case "md":
      return { height: "50px", width: "50px" };
    case "sm":
      return { height: "30px", width: "30px" };
    default:
      return { height: "50px", width: "50px" };
  }
};

const PictureAvatar = ({
  image,
  firstName,
  lastName,
  userId,
  size = "md",
  staticImage = false,
}) => {
  const { company } = useAppContextController();
  const IMAGE_SERVER = company?.imageUrl;
  const [avatarImage, setAvatarImage] = useState(image);
  const [thisUserId, setThisUserId] = useState(userId);

  useMemo(() => setAvatarImage(image), [image]);

  const { logoutAuth0User } = useSessionAuth();

  useEffect(() => {
    let isMounted = true; // Flag to track mounting status

    const fetchUser = async (filter) => {
      try {
        const usr = await fetchSingleUser(filter);
        if (isMounted && usr?.profileImg) {
          setAvatarImage(usr.profileImg);
          setThisUserId(usr?._id?.toString());
        }
      } catch (error) {
        if (String(error).includes("401") || error?.response?.status === 401) {
          logoutAuth0User();
        }
      }
    };

    if (!image && !userId && firstName && lastName && isMounted) {
      fetchUser({
        firstName,
        lastName,
      });
    }

    return () => {
      isMounted = false; // Set flag to false when the component unmounts
    };
  }, [image, userId, firstName, lastName]);

  function stringAvatar(record) {
    if (record?.firstName && record?.lastName) {
      return `${record?.firstName[0]}${record?.lastName[0]}`;
    }
    // Ensure it returns an empty string or some default text instead of an object
    return "";
  }

  let imageUrl = null;
  if (avatarImage && avatarImage.substring(0, 5) === "https") imageUrl = avatarImage;
  else if (avatarImage && IMAGE_SERVER && thisUserId)
    imageUrl = `${IMAGE_SERVER}/users/${thisUserId}/photo/${avatarImage}`;
  const classes = useStyle();
  const { primary } = colors;

  return (
    <div className={`${classes.avatarContainer} ${classes.showOnHover}`}>
      {/* <Tooltip title={firstName && lastName ? `${firstName} ${lastName}` : ''}> */}
      {imageUrl || (staticImage && image) ? (
        <Avatar
          alt={`${firstName} ${lastName} picture`}
          src={staticImage && image ? image : imageUrl}
          sx={avatarSize(size)}
        />
      ) : (
        <Avatar alt={`${firstName} ${lastName} picture`} sx={avatarSize(size)}>
          {stringAvatar({ firstName, lastName })}
        </Avatar>
      )}
      {imageUrl || (staticImage && image) ? (
        <Avatar
          alt={`${firstName} ${lastName} picture enlarged`}
          src={staticImage && image ? image : imageUrl}
          sx={{ height: "175px", width: "175px" }} // Adjust size for enlarged avatar
          className={classes.avatarHover}
        />
      ) : (
        <Avatar
          alt={`${firstName} ${lastName} picture enlarged`}
          sx={{ height: "175px", width: "175px" }} // Adjust size for enlarged avatar
          className={classes.avatarHover}
        >
          {stringAvatar({ firstName, lastName })}
        </Avatar>
      )}
      {/* </Tooltip> */}
    </div>
  );
};

export default PictureAvatar;
