import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Card, Grid, Icon, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";
import { Controller, useForm } from "react-hook-form";
import DataTable from "components/DataTable";
import FiltersList from "components/FiltersList";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PictureAvatar from "components/PictureAvatar";
import Searchbar from "components/Searchbar";
import StaffingPoolExportModal from "components/StaffingPoolExportModal";
import { useAppContextController } from "context/AppContext";
import fetchPartnerApplicants from "layouts/pages/applicants/actions/fetchPartnerApplicants";
import ApplicantActionModal from "layouts/pages/applicants/components/ApplicantActionModal";

import PartnerApplicantsTableActions from "layouts/pages/partners/components/PartnerApplicantsTableActions";
import moment from "moment";
import { SnackbarProvider } from "notistack";
import useYupValidationResolver from "hooks/useYupValidationResolver";
import { memberSchema } from "data/member";
import useSort from "utils/useSort";
import MembersModal from "../MembersModal";
import ApplicantNotesList from "../../../../../components/ApplicantNotesList";
import MemberPartnerStatus from "../MemberPartnerStatus";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
  },
  addButton: {
    fontSize: 40,
  },
});

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const PartnerApplicantsTable = ({
  fetchAll = false,
  title = "Partner Applicants",
  filters,
  pageSize = 5,
  showFiltersList = true,
  showSearchBar = true,
  navigateToAction = "",
  partnerSlug = null,
  showEventStatus = false,
  setSelectedId,
  staffingPoolName,
}) => {
  const queryClient = useQueryClient();
  const [dropdowns, setDropdowns] = useState();
  const resolver = useYupValidationResolver(memberSchema(dropdowns));
  const { action, id } = useParams();
  const setNavigateUrl = useNavigate();
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [toastAlert, setToastAlert] = useState(false);
  const classes = useStyle();
  const { currentPartner } = useAppContextController();
  const [currentMember, setCurrentMember] = useState(null);
  const [modalInfo, setModalInfo] = useState({ data: null });
  const [isBulkMessageModalOpen, toggleBulkMessageModal] = useState(false);
  const [isStaffingPoolModalOpen, toggleStaffingPoolModalOpen] = useState(false);
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [candidates, setCandidates] = useState({});
  const [membersModalOpen, setMembersModalOpen] = useState(false);
  const [memberNotesListOpen, setMemberNotesListOpen] = useState(false);
  const [memberFilters, setMemberFilters] = useState({});
  // Pagination state

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(pageSize); // Default pageSize higher than 10. Don't use preferred pageSize

  const { order, orderBy, toggleSort } = useSort();

  const options = fetchAll ? { fetchAll, order, orderBy } : { page, limit, order, orderBy };
  const allOptions = {
    ...options,
    filters: {
      status: "Member",
      "partners.partnerSlug": partnerSlug,
    },
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchPartnerApplicants,
  } = useQuery(["partnersapplicants", allOptions], () => fetchPartnerApplicants(allOptions));

  const {
    register,
    handleSubmit,
    trigger,
    clearErrors,
    getValues,
    reset,
    setValue,
    setError,
    control,
    watch,
    Control,
    formState: { errors, isSubmitting, isDirty, dirtyFields },
  } = useForm({ mode: "onBlur", defaultValues: currentMember, resolver });

  const columns = [
    {
      title: "Member",
      field: "rosterStatus",
      customCell: (eurl, field, row) => {
        // get memberStatus for this row
        const partners = row?.partners ? row.partners : [];
        const thisPartner = partners.find(
          (partner) => partner.partnerSlug === currentPartner?.slug
        );
        return (
          <MemberPartnerStatus
            id={row?._id}
            status={thisPartner?.status}
            setToastAlert={setToastAlert}
            queryClient={queryClient}
            member={row}
            source="membersTable"
            showTooltip={false}
            refetchPartnerApplicants={refetchPartnerApplicants}
          />
        );
      },
    },
    {
      title: "Avatar",
      field: "profileImg",
      customCell: (image, field, row) => (
        <PictureAvatar
          image={row?.profileImg}
          firstName={row?.firstName}
          lastName={row?.lastName}
          // userId={row?.userRecordId ? row.userRecordId : row?._id}
          size="md"
        />
      ),
    },
    {
      title: "Status",
      field: "partners",
      customCell: (partnersArray) => {
        const thisPartner = partnersArray.find(
          (partner) => partner.partnerSlug === currentPartner?.slug
        );
        return <> {thisPartner?.status}</>;
      },
    },
    {
      title: "Last Name",
      field: "lastName",
    },
    {
      title: "First Name",
      field: "firstName",
    },
    { title: "Phone", field: "phone" },
    { title: "Email", field: "email" },
    {
      title: "T-Shirt Size",
      field: "tShirtSize",
    },
    // {
    //   title: "Date",
    //   field: "createdDate",
    //   customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    // },
    {
      title: "Notes",
      field: "_id",
      customCell: (rowId, field, row) => (
        <PartnerApplicantsTableActions
          id={rowId}
          row={row}
          setCurrentApplicant={setCurrentMember}
          setModalInfo={setModalInfo}
          setNavigateUrl={setNavigateUrl}
          setGenericModalOpen={setGenericModalOpen}
          setMembersModalOpen={setMembersModalOpen}
          setMemberNotesListOpen={setMemberNotesListOpen}
        />
      ),
    },
  ];

  const searchColumns = [
    // {
    //   title: "Avatar",
    //   field: "profileImg",
    //   customCell: (image, field, row) => (
    //     <PictureAvatar
    //       image={row?.profileImg}
    //       firstName={row?.firstName}
    //       lastName={row?.lastName}
    //       // userId={row?.userRecordId ? row.userRecordId : row?._id}
    //       size="md"
    //     />
    //   ),
    // },
    {
      title: "Member",
      field: "rosterStatus",
      customCell: (eurl, row) => {
        // get memberStatus for this row
        const partners = row?.partners ? row.partners : [];
        const thisPartner = partners.find(
          (partner) => partner?.partnerSlug === currentPartner?.slug
        );

        return (
          <MemberPartnerStatus
            id={row?._id}
            status={thisPartner?.status}
            setToastAlert={setToastAlert}
            queryClient={queryClient}
            member={row}
            source="searchBar"
            showTooltip={false}
            refetchPartnerApplicants={refetchPartnerApplicants}
          />
        );
      },
    },

    {
      title: "Last Name",
      field: "lastName",
      // customCell: (name, field, row) =>
      //   row ? (
      //     <CustomWidthTooltip
      //       title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
      //       sx={{ color: "red" }}
      //     >
      //       <div>{name}</div>
      //     </CustomWidthTooltip>
      //   ) : null,
    },
    {
      title: "First Name",
      field: "firstName",
      // customCell: (name, field, row) =>
      //   row ? (
      //     <CustomWidthTooltip
      //       title={`${row?.email || ""} \n ${row?.city || ""} ${row?.state || ""}`}
      //       sx={{ color: "red" }}
      //     >
      //       <div>{name}</div>
      //     </CustomWidthTooltip>
      //   ) : null,
    },
    {
      title: "Date",
      field: "createdDate",
      customCell: (date) => (date ? moment(date).format("YYYY-MM-DD") : ""),
    },
    // {
    //   title: "Partner Actions",
    //   field: "_id",
    //   customCell: (rowId, field, row) => (
    //     <PartnerApplicantsTableActions
    //       id={rowId}
    //       row={row}
    //       setCurrentApplicant={setCurrentApplicant}
    //       setModalInfo={setModalInfo}
    //       setNavigateUrl={setNavigateUrl}
    //       setGenericModalOpen={setGenericModalOpen}
    //     />
    //   ),
    // },
  ];

  const handleRowClick = (row, e) => {
    setCurrentMember(row);
    setMembersModalOpen(true);
  };

  const addNew = (e) => {
    setCurrentMember(null);

    setMembersModalOpen(true);
    e.stopPropagation();
  };

  const handleNotesSuccess = () => {
    queryClient.invalidateQueries(["partnersapplicants"]);
    queryClient.invalidateQueries(["members"]);
  };

  const sortLastFirst = (a, b) => {
    const o1 = a.lastName.toLowerCase();
    const o2 = b.lastName.toLowerCase();

    const p1 = a.firstName.toLowerCase();
    const p2 = b.firstName.toLowerCase();

    if (o1 < o2) return -1;
    if (o1 > o2) return 1;
    if (p1 < p2) return -1;
    if (p1 > p2) return 1;
    return 0;
  };

  useEffect(() => {
    if (!applicants) {
      setCandidates([]);
      return;
    }
    setCandidates(applicants);
  }, [applicants]);

  return (
    <>
      <Card className={classes.box}>
        <SnackbarProvider
          maxSnack={3}
          open={toastAlert}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={() => setToastAlert(false)}
          key="top-center"
        >
          <Grid container spacing={3} alignItems="center" justifyContent="space-between" py={1}>
            <Grid item>
              <MDBox display="flex">
                <MDBox
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  variant="gradient"
                  bgColor="warning"
                  color="white"
                  borderRadius="xl"
                  ml={3}
                  mt={-4}
                >
                  <Icon fontSize="large">people</Icon>
                </MDBox>
                <MDTypography variant="h5" color="dark" sx={{ ml: 2 }}>
                  {title}
                </MDTypography>
                <MDBox ml={3}>
                  {showFiltersList && (
                    <Grid item xs={6}>
                      <FiltersList filters={memberFilters} setFilters={setMemberFilters} />
                    </Grid>
                  )}
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item display="flex" alignItems="center">
              <Searchbar
                fetch={fetchPartnerApplicants}
                fetchAll={false}
                placeholder="Search Members"
                columns={searchColumns}
                queryCharacterLimit={3}
                resultsLimit={15}
                setFilters={setMemberFilters}
                setPage={setPage}
                searchBy={["firstName", "lastName"]}
                filterBy="_id"
                scrollLimit={200}
                sortFunction={sortLastFirst}
                // onRowClick={(item) => {
                //   if (item?._id) setSelectedId(item._id);
                // }}
              />

              <MDBox>
                {/* <Tooltip title="Bulk Message">
                  <IconButton color="error" onClick={() => toggleBulkMessageModal(true)}>
                    <Mail fontSize="large" />
                  </IconButton>
                </Tooltip>
                {currentPartner?.slug && (
                  <Tooltip title={`Export ${staffingPoolName} Staffing Pool`}>
                    <IconButton color="info" onClick={() => toggleStaffingPoolModalOpen(true)}>
                      <FileDownload />
                    </IconButton>
                  </Tooltip>
                )} */}
                <Tooltip title="Create a New Member">
                  <IconButton className={classes.addButton} color="info" onClick={addNew}>
                    <AddCircleRoundedIcon />
                  </IconButton>
                </Tooltip>
              </MDBox>
            </Grid>
          </Grid>
          <MDBox ml={3}>
            {/* <MDBox display="flex" flexWrap="wrap" alignItems="center" rowGap="1rem">
              <ApplicantsFilterCards
                parent="Partners"
                setFilters={setApplicantsFilters}
                filters={{ ...filters, ...applicantsFilters }}
              />
              <VenueSearch
                data={candidates}
                setData={setCandidates}
                fetchedData={applicants}
                setPage={setPage}
                applicantsFilters={applicantsFilters}
                label={type === "Applicants" ? "Applicants" : "Staff"}
              />
            </MDBox> */}

            <MDBox pt={2} pb={2}>
              <DataTable
                columns={columns}
                data={candidates}
                page={page}
                limit={limit}
                setPage={setPage}
                setLimit={setLimit}
                isLoading={isLoading}
                onRowClick={(row, e) => handleRowClick(row, e)}
                order={order}
                orderBy="lastName:asc,firstName"
                toggleSort={toggleSort}
                fetchAll={fetchAll}
                defaultSort="lastName:asc,firstName"
                idField="_id"
              />
            </MDBox>
            {genericModalOpen ? (
              <ApplicantActionModal
                open={genericModalOpen}
                setOpen={setGenericModalOpen}
                modalInfo={{ type: "applicantmail", data: currentMember }}
                setToastAlert={setToastAlert}
                currentApplicant={currentMember}
                messageContext="Applicant"
                attachmentContext="Applicant"
              />
            ) : null}
          </MDBox>
        </SnackbarProvider>
        {membersModalOpen && (
          <MembersModal
            currentApplicant={currentMember}
            open={membersModalOpen}
            setDropdowns={setDropdowns}
            setOpen={setMembersModalOpen}
            handleSubmit={handleSubmit}
            Controller={Controller}
            control={control}
            setValue={setValue}
            clearErrors={clearErrors}
            reset={reset}
            errors={errors}
            currentPartner={currentPartner}
            dirtyFields={dirtyFields}
            getValues={getValues}
            setError={setError}
          />
        )}
        <ApplicantNotesList
          currentApplicant={currentMember}
          open={memberNotesListOpen}
          setOpen={setMemberNotesListOpen}
          onSuccess={handleNotesSuccess}
        />
      </Card>
    </>
  );
};

// Setting default values for the props
PartnerApplicantsTable.defaultProps = {
  // currentPartner: {},
  fetchAll: false,
  title: "Applicants",
  filters: null,
  showFiltersList: true,
};

// Typechecking props
PartnerApplicantsTable.propTypes = {
  // currentPartner: PropTypes.object,
  fetchAll: PropTypes.bool,
  title: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.string),
  showFiltersList: PropTypes.bool,
};

export default PartnerApplicantsTable;
