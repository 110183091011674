import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import { useMemo, useRef, useState } from "react";

import { useAppContextController } from "context/AppContext";
import Calendar from "examples/Calendar";
import CalendarDayModal from "layouts/pages/events/components/CalendarDayModal";
import EventsPreviewModal from "layouts/pages/events/components/EventsPreviewModal";
import CustomMenu from "components/CustomizedMui/CustomMenu";
import moment from "moment";
import MonthSelectionMenu from "components/MonthSelectionMenu";
import MDBox from "components/MDBox";
import { BorderColor } from "@mui/icons-material";
import CircularLoader from "components/CircularLoader";

const EVENT_APPLICANT_STATUS_COLOR = {
  Roster: "success",
  Waitlist: "purple",
  Request: "warning",
};

const EventCalendar = ({
  events,
  isLoading,
  sortByEventDate,
  calendarListView,
  onClickEventOverride,
  onSetMiddleDate,
}) => {
  const { currentEvent, setCurrentEvent, currentApplicant } = useAppContextController();
  const eventsData = events?.data?.length ? events.data : events?.data?.data;

  const [showEventModal, setShowEventModal] = useState(false);
  const [showDayModal, setShowDayModal] = useState(false);
  const [dayEvents, setDayEvents] = useState([]);
  const [dateSelected, setDateSelected] = useState("");
  const setDateToFirst = (date) => new Date(new Date(date).setDate(1)).toISOString().split("T")[0];
  const [startingDate, setStartingDate] = useState(setDateToFirst(new Date()));
  const [anchorEl, setAnchorEl] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);

  const calendarRef = useRef(null);
  const calendarApi = calendarRef.current?.getApi();
  const currentDateCalendar = calendarApi?.getDate();

  const calendarEventsData = useMemo(() => {
    if (!isLoading && eventsData?.length) {
      let eventDayIndex = 0;
      let previousDate = null;
      let earliestDate = new Date();

      const retData = eventsData.sort(sortByEventDate).map((item) => {
        const currentDate = new Date(item.eventDate).getDate();
        if (new Date(item.eventDate).getDate() !== previousDate) {
          eventDayIndex = 0;
        } else {
          eventDayIndex += 1;
        }
        previousDate = currentDate;
        if (!earliestDate && currentDate > new Date()) earliestDate = new Date(currentDate);
        if (
          earliestDate &&
          new Date(earliestDate).toISOString().split("T")[0] >
            new Date(currentDate).toISOString().split("T")[0]
        )
          earliestDate = new Date(currentDate);

        const className = EVENT_APPLICANT_STATUS_COLOR[item?.rosterStatus] || "info";
        return {
          title: `${item.eventName}${item.reportTimeTBD ? " (TBD)" : ""}`,
          start: item.reportTimeTBD
            ? new Date(item.eventDate).toISOString().split("T")[0]
            : new Date(item.eventDate).toISOString(),
          eventId: item._id,
          ...(item.reportTimeTBD ? { timeText: item.reportTimeTBD } : {}),
          allDay: item.reportTimeTBD,
          className,
        };
      });
      if (earliestDate.getTime() < new Date().getTime())
        setStartingDate(setDateToFirst(new Date()));
      else setStartingDate(setDateToFirst(new Date(earliestDate)));
      return retData;
    }
    return [];
  }, [eventsData, isLoading, sortByEventDate]);

  const renderEventContent = (eventInfo) => {
    return (
      <>
        <b>{eventInfo.timeText}</b> - <i>{eventInfo.event.title}</i>
      </>
    );
  };

  const handleDateClick = (d) => {
    // at bottom where you instantiate the EventDayModal:
    //   make date, dayEvents - state vars give it a date parameter    "2022-07-15"
    function addDays(date, days) {
      date.setDate(date.getDate() + days);
      return date;
    }
    const dateSel = d.dateStr;
    if (dateSel) setDateSelected(dateSel);
    // alert(addDays(new Date(dateSel), 1).toLocaleDateString())
    const devents = eventsData.filter((item) => {
      return (
        new Date(item.eventDate).toLocaleDateString() ===
        addDays(new Date(dateSel), 1).toLocaleDateString()
      );
    });
    if (devents?.length && dateSel) {
      setDayEvents(devents);
      setShowDayModal(true);
    }
  };

  const handleClickMonth = (goToDate) => {
    setLastDate(calendarApi.getDate());
    calendarApi.gotoDate(goToDate.toDate());
  };

  const handleEventClick = (d) => {
    const eventId = d.event?.extendedProps?.eventId;
    if (eventId) {
      const eventPreviewEvent = eventsData.find((item) => item._id === eventId);
      if (onClickEventOverride) onClickEventOverride(eventPreviewEvent);
      else {
        setCurrentEvent(eventPreviewEvent);
        setShowEventModal(true);
      }
    }
    // set up following parameter
    // if (events) setCurrentEvent(events.data[0]);
    // alert(d.event.extendedProps?.eventId)
  };

  const onPressJumpTo = (e) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const onPressGoBack = (e) => {
    e.stopPropagation();
    if (calendarApi) {
      calendarApi.gotoDate(lastDate);
      setLastDate(null);
    }
  };

  const onCalendarDataSet = (data) => {
    const midDate = new Date((data.start.getTime() + data.end.getTime()) / 2);
    onSetMiddleDate?.(midDate);
  };

  return (
    <>
      <MDBox sx={{ position: "relative" }}>
        <Calendar
          ref={calendarRef}
          headerToolbar={{
            left: lastDate == null ? "prev,next jumpToButton" : "prev,next goBackButton",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          initialDate={startingDate}
          eventClick={handleEventClick}
          events={calendarEventsData}
          dateClick={handleDateClick}
          header={{
            left: "prev,next",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          plugins={[dayGridPlugin, timeGridPlugin]}
          selectable
          // eslint-disable-next-line react/jsx-no-bind
          eventContent={renderEventContent}
          // editable
          customButtons={{
            jumpToButton: {
              text: "Jump To",
              click: onPressJumpTo,
            },
            goBackButton: {
              text: "< Back",
              click: onPressGoBack,
            },
          }}
          datesSet={onCalendarDataSet}
        />
        <CircularLoader hasBackdrop={false} isLoading={isLoading} color="info" />
      </MDBox>
      {showEventModal && calendarListView === "Calendar" && (
        <EventsPreviewModal
          open={showEventModal}
          setOpen={setShowEventModal}
          eventPreview={currentEvent}
          setEventPreview={setCurrentEvent}
        />
      )}
      {showDayModal && calendarListView === "Calendar" && (
        <CalendarDayModal
          showDayModal={showDayModal}
          setShowDayModal={setShowDayModal}
          dateSelected={dateSelected}
          dayEvents={dayEvents}
        />
      )}
      <MonthSelectionMenu
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        handleClick={handleClickMonth}
        startingDate={startingDate}
        currentDate={currentDateCalendar}
      />
      <div>&nbsp;</div>
    </>
  );
};

export default EventCalendar;
