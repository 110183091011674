import { useAppContextController } from "context/AppContext";
import { useState } from "react";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import createApplicant from "layouts/pages/applicants/actions/createApplicant";

const usePartnerLeaderChecker = ({currentPartner}) => {
  const { currentLoggedUser } = useAppContextController();

  const [currentPartnerLeader, setCurrentPartnerLeader] = useState(null);
  const [isLoadingPartnerLeader, setIsLoadingPartnerLeader] = useState(null);

  const checkPartnerLeader = async () => {
    setIsLoadingPartnerLeader(true);
    setCurrentPartnerLeader(null);

    try {
      if (!currentPartner || currentPartner.status === "Deleted") {
        setIsLoadingPartnerLeader(false);
        return;
      }

      // Check if partner already has an assigned partner
      let applicantsResult = await fetchApplicants({
        filters: { partnerSlug: currentPartner.slug, status: "Leader" },
        fetchAll: true,
      });

      if (!applicantsResult?.data) {
        // No applicant using this partner ID. Try with email or phone
        if (currentPartner.leader?.email) {
          applicantsResult = await fetchApplicants({
            filters: { email: currentPartner.leader?.email, status: "Leader" },
            fetchAll: true,
          });
        } else if (currentPartner.leader?.phone) {
          applicantsResult = await fetchApplicants({
            filters: { phone: currentPartner.leader?.phone, status: "Leader" },
            fetchAll: true,
          });
        } else {
          setIsLoadingPartnerLeader(false);
          return;
        }
      }

      if (applicantsResult && applicantsResult.data && applicantsResult.data.length) {
        // Applicant exists
        setCurrentPartnerLeader(applicantsResult.data[0]);
      } else {
        // Applicant doesn't exist. Create one and select it
        const data = {
          email: currentPartner?.leader?.email,
          phone: currentPartner?.leader.phone,
          address: currentPartner?.address,
          city: currentPartner?.city,
          state: currentPartner?.state,
          zip: currentPartner?.zip,
          firstName: currentPartner?.leader.firstName,
          lastName: currentPartner?.leader.lastName,
          status: "Leader",
          modifiedDate: new Date(),
          createdDate: new Date(),
          recordLocked: "No",
          //   payrollFirstName: currentPartner?.payrollFirstName,
          //   payrollLastName: currentPartner?.payrollLastName,
          //   fein: currentPartner?.fein,
          partnerSlug: currentPartner.slug,
        };
        data.jobs = [];
        data.events = [];
        data.venues = [];
        data.createAgent = currentLoggedUser._id;
        const resultCreate = await createApplicant(data);
        setCurrentPartnerLeader({ ...data, _id: resultCreate.insertedId });
      }
      // setOpenMessageModal(true);
      setIsLoadingPartnerLeader(false);
    } catch (e) {
      setIsLoadingPartnerLeader(false);
    }
  };

  return {
    currentPartnerLeader,
    isLoadingPartnerLeader,
    checkPartnerLeader
  };
};

export default usePartnerLeaderChecker;
