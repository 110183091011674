import { useEffect, useState, useMemo } from "react";
import useBadgeValues from "hooks/useBadgeValues";
import { useNavigate } from "react-router-dom";
import FilterCardBar from "components/FilterCardBar";
import {
  Info as InfoIcon,
  CheckCircle as CheckCircleIcon,
  Cancel as CancelIcon,
  PersonOff as PersonOffIcon,
  Handshake as HandshakeIcon,
  Login as LoginIcon,
  CheckCircleRounded,
  PersonOffRounded,
  Block,
} from "@mui/icons-material";
import { useAppContextController } from "context/AppContext";

const StaffingPoolFilterCards = ({
  filters,
  setFilters,
  setPage,
  selectedCardNumber,
  useElemMatch,
  parent = "employees",
  initialCards = null,
  doReplace = false,
  baseFilters,
}) => {
  const navigate = useNavigate();
  const { setCurrentApplicant, company } = useAppContextController();
  const mainCompanyField = parent === "Customers" ? "companies.status" : "jobs.status";
  const initialFilterCards = initialCards || [
    ...(company?.companyType === "Venue"
      ? [
          {
            color: "info",
            icon: <InfoIcon fontSize="large" color="info" />,
            title: "All",
            tooltip: "Show All Employees",
            number: 0,
            filters: {
              status: "Employee;Partner",
              employmentStatus: "Active;Inactive;Terminated",
              "venues.status": "StaffingPool;Pending;Locked",
            },
          },
          {
            color: "success",
            icon: <CheckCircleIcon fontSize="large" color="success" />,
            title: "Active",
            tooltip: "",
            number: 0,
            filters: {
              status: "Employee;Partner",
              employmentStatus: "Active",
              "venues.status": "StaffingPool",
            },
          },
          {
            color: "primary",
            icon: <HandshakeIcon fontSize="large" color="success" />,
            title: "Partner",
            tooltip: "Partner Employees",
            number: 0,
            entity: "partners",
            filters: { status: "Partner", "venues.status": "StaffingPool" },
            badgeFilters: {
              status: "Active",
              "venues.status": "StaffingPool",
              "venues.venueSlug": filters?.["venues.venueSlug"],
            }
          },
          {
            color: "info",
            icon: <LoginIcon fontSize="large" color="info" />,
            title: "LoggedIn",
            tooltip: "Users Have Logged In",
            number: 0,
            filters: {
              status: "Employee;Partner",
              loginVerified: "Yes",
              "venues.status": "StaffingPool",
            },
          },
          {
            color: "info",
            icon: <LoginIcon fontSize="large" color="info" />,
            title: "No Login",
            tooltip: "Users Have Not Logged In",
            number: 0,
            filters: {
              status: "Employee;Partner",
              loginVerified: "No",
              "venues.status": "StaffingPool",
            },
          },
          {
            color: "warning",
            icon: <PersonOffIcon fontSize="large" color="warning" />,
            title: "Inactive",
            tooltip: "Inactive Employees",
            number: 0,
            filters: {
              status: "Employee;Partner",
              employmentStatus: "Inactive",
              "venues.status": "StaffingPool",
            },
          },
          {
            color: "error",
            icon: <CancelIcon fontSize="large" color="error" />,
            title: "Terminated",
            tooltip: "Terminated Employees",
            number: 0,
            filters: {
              status: "Employee;Partner",
              employmentStatus: "Terminated",
              "venues.status": "StaffingPool",
            },
          },
          {
            icon: <Block color="error" fontSize="large" />,
            title: "Do Not Use",
            color: "error",
            tooltip: "Do Not Use",
            number: 0,
            filters: {
              status: "Employee;Partner",
              employmentStatus: "Active;Inactive;Terminated",
              "venues.status": "StaffingPool;Pending;Locked",
              isDnu: "Yes",
            },
          },
        ]
      : [
          {
            color: "info",
            icon: <InfoIcon fontSize="large" color="info" />,
            title: "All",
            tooltip: "Show All Employees",
            default: true,
            number: 0,
            filterField: mainCompanyField,
            filterValue:
              parent === "Customers"
                ? "On Assignment;Pending;Inactive;Terminated"
                : "Active;On Assignment;Terminated;Inactive",
          },
          {
            color: "success",
            icon: <CheckCircleIcon fontSize="large" color="success" />,
            title: "Assignment",
            tooltip: "",
            number: 0,
            filterField: mainCompanyField,
            filterValue: "On Assignment",
          },
          {
            color: "secondary",
            icon: <CheckCircleRounded fontSize="large" color="secondary" />,
            title: "Inactive",
            number: 0,
            tooltip: "Inactive Employees",
            filterField: mainCompanyField,
            filterValue: "Inactive",
          },
          {
            icon: <Block color="error" fontSize="large" />,
            title: "Do Not Use",
            color: "error",
            tooltip: "Do Not Use",
            number: 0,
            filters: {
              [mainCompanyField]:
                parent === "Customers"
                  ? "On Assignment;Pending;Inactive;Terminated"
                  : "Active;On Assignment;Terminated;Inactive",
              isDnu: "Yes",
            },
          },
          // {
          //   color: "warning",
          //   icon: <PersonOffIcon fontSize="large" color="warning" />,
          //   title: "Do Not Use",
          //   tooltip: "DNU Employees",
          //   number: 0,
          //   filterField: parent === "Customers" ? "companies.status" : "employmentStatus",
          //   filterValue: "DNU",
          // },
        ]),

    // ...(company?.companyType === "Company"
    //   ? [
    //       {
    //         color: "success",
    //         icon: <CheckCircleRounded fontSize="large" />,
    //         title: "Available",
    //         number: 0,
    //         tooltip: "Available Employees",
    //         filterField: "isAvailable",
    //         filterValue: "Yes",
    //       },
    //       {
    //         color: "warning",
    //         icon: <PersonOffRounded fontSize="large" />,
    //         title: "Unavailable",
    //         number: 0,
    //         tooltip: "Unavailable Employees",
    //         filterField: "isAvailable",
    //         filterValue: "No",
    //       },
    //     ]
    //   : []),
  ];

  const [refreshBadges, setRefreshBadges] = useState(false);

  const { cards } = useBadgeValues({
    initialCards: initialFilterCards,
    filters: doReplace && baseFilters ? baseFilters : { ...(baseFilters ?? {}), ...filters },
    entity: "applicants",
    refreshBadges,
    useElemMatch,
  });

  const setEmployeeFilter = (filter) => {
    // if (!["Venues", "Jobs"].includes(parent)) navigate("/employees");
    if (parent === "employees") {
      navigate("/employees");
    }

    const newFilters = { ...filters };
    delete newFilters._id;

    setCurrentApplicant(null);
    if (doReplace) setFilters({ ...filter });
    else setFilters({ ...newFilters, ...filter });
    setRefreshBadges((prev) => !prev);
  };

  const venueFilter = filters?.["venues.venueSlug"];
  const jobsFilter = filters?.["jobs.jobSlug"];
  useEffect(() => {
    setRefreshBadges((prev) => !prev);
  }, [venueFilter, jobsFilter]);
  const initialCardIndex = initialFilterCards.findIndex((card) => card.default);

  return (
    <FilterCardBar
      cards={cards}
      bgColor="primary"
      setExistingFilters={setFilters}
      setFilters={setEmployeeFilter}
      filters={{ ...filters }}
      initialIndex={initialCardIndex}
      setPage={setPage}
    />
  );
};

export default StaffingPoolFilterCards;
