import { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  CircularProgress,
  Grid,
  List,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MDBox from "components/MDBox";
import { useQuery } from "react-query";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import { useAppContextController } from "context/AppContext";
import { isEqual } from "lodash";

const useStyle = makeStyles({
  box: {
    marginTop: 20,
    overflow: "visible!important",
    height: "100%",
  },
  addButton: {
    fontSize: 40,
  },
  root: {
    height: "350px",
  },
  list: {
    width: "85%",
    height: "350px",
    overflowY: "scroll",
    border: "solid black 1px",
    padding: 2,
  },
  button: {
    margin: "8px",
    width: "100px",
  },
});

const StaffingPool = ({ defaultValues, setValue }) => {
  const { currentJob } = useAppContextController();
  const classes = useStyle();
  const [rightList, setRightList] = useState([]);
  const [selectedLeftItem, setSelectedLeftItem] = useState(null);
  const [selectedRightItem, setSelectedRightItem] = useState(null);

  const [leftList, setLeftList] = useState([]);

  useEffect(() => {
    if (rightList?.length > 0) {
      setValue("shiftRoster", rightList, { shouldDirty: !isEqual(rightList, defaultValues) });
    }
  }, [rightList]);

  const allOptions = {
    fetchAll: true,
    includeVerified: true,
    filters: {
      // status: type === "Staffing" ? "Employee" : "Applicant",
      // status: "Employee",
      "jobs.jobSlug": currentJob?.jobSlug,
    },
    useOrTags: true,
    useElemMatch: true,
  };

  const {
    data: applicants,
    isLoading,
    refetch: refetchApplicants,
  } = useQuery(["shiftJobsApplicants", allOptions], () => fetchApplicants(allOptions));

  const sortList = (list) => {
    return list.sort((a, b) => {
      if (a.fullName > b.fullName) return 1;
      return -1;
    });
  };

  const filteredList = useMemo(() => {
    if (!applicants) return [];
    const applicantsData = applicants.data.map((applicant) => ({
      _id: applicant._id,
      fullName: `${applicant.firstName} ${applicant.lastName}`,
      firstName: applicant.firstName,
      lastName: applicant.lastName,
      email: applicant.email,
    }));
    // sort
    return sortList(applicantsData);
  }, [applicants]);

  useEffect(() => {
    if (defaultValues && filteredList?.length > 0) {
      const defaultSorted = sortList(defaultValues);
      const right = filteredList.filter((item) =>
        defaultSorted.find((rightItem) => rightItem._id === item._id)
      );
      setRightList(right);
      const left = filteredList.filter(
        (item) => !defaultSorted.find((rightItem) => rightItem._id === item._id)
      );
      setLeftList(left);
    }
  }, [filteredList, defaultValues]);

  if (isLoading)
    return (
      <Box display="flex" justifyContent="center" mt={3}>
        <CircularProgress color="info" />
      </Box>
    );

  const moveItem = (index, sourceList, targetList, direction) => {
    const source = [...sourceList];
    const target = [...targetList];
    const item = source.splice(index, 1);
    target.push(...item);
    source.sort();
    target.sort();
    if (direction === "left") {
      setLeftList(source);
      setRightList(target);
    } else {
      setRightList(source);
      setLeftList(target);
    }
  };
  const handleLeftItemClick = (index) => {
    setSelectedLeftItem(index);
    setSelectedRightItem(null);
  };

  const handleRightItemClick = (index) => {
    setSelectedRightItem(index);
    setSelectedLeftItem(null);
  };

  const handleButtonClick = (direction) => {
    if (direction === "left") {
      if (selectedLeftItem !== null) {
        moveItem(selectedLeftItem, leftList, rightList, direction);
        setSelectedLeftItem(null);
      }
    } else if (direction === "right") {
      if (selectedRightItem !== null) {
        moveItem(selectedRightItem, rightList, leftList, direction);
        setSelectedRightItem(null);
      }
    }
  };

  const handleButtonDoubleClick = (index, direction) => {
    if (direction === "left") {
      moveItem(index, leftList, rightList, direction);
      setSelectedLeftItem(null);
    } else if (direction === "right") {
      moveItem(index, rightList, leftList, direction);
      setSelectedRightItem(null);
    }
  };

  return (
    <Grid container>
      <Grid item sm={5}>
        <List className={classes.list}>
          {leftList?.map((item, index) => (
            <ListItemButton
              key={item?._id}
              selected={index === selectedLeftItem}
              onClick={() => handleLeftItemClick(index)}
              onDoubleClick={() => {
                handleButtonDoubleClick(index, "left");
              }}
            >
              <ListItemText primary={item?.fullName} />
            </ListItemButton>
          ))}
        </List>
      </Grid>
      <Grid item sm={2} display="flex">
        <Grid container direction="column" alignItems="center" justifyContent="center">
          <Button
            className={classes.button}
            variant="contained"
            color="success"
            onClick={() => handleButtonClick("left")}
          >
            &gt;
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="error"
            onClick={() => handleButtonClick("right")}
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item sm={5}>
        <MDBox display="flex" justifyContent="flex-end">
          <List className={classes.list}>
            {rightList?.map((item, index) => (
              <ListItemButton
                key={item?._id}
                selected={index === selectedRightItem}
                onClick={() => handleRightItemClick(index)}
                onDoubleClick={() => {
                  handleButtonDoubleClick(index, "right");
                }}
              >
                <ListItemText primary={item.fullName} />
              </ListItemButton>
            ))}
          </List>
        </MDBox>
      </Grid>
    </Grid>
  );
};

export default StaffingPool;
