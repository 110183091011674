import * as yup from "yup";

export const filterEditorFormSchema = yup.object().shape({
  andOr: yup.string(),
  filterEditorModalRows: yup
    .array()
    .of(
      yup.object().shape({
        field: yup.string().required("Field is Required").nullable(),
        isOrNot: yup.string().required("Field is Required").nullable(),
        operator: yup.string().required("Field is Required").nullable(),
        rowValue: yup.string().required("Field is Required").nullable(),
      })
    )
    .min(1, "Please add at least one filter"),
});
