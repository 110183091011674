// import React from "react";
import ApplicantMoreButton from "components/ApplicantMoreButton";
import TableAction from "components/TableAction";
import PropTypes from "prop-types";

const EmployeesTableActions = ({
  id,
  row,
  setCurrentApplicant,
  setNavigateUrl,
  setGenericModalOpen,
  setModalInfo,
  company,
  setPage,
  onRefresh,
}) => {
  const onClickHandler = (action, e) => {
    if (action === "employeemail") {
      e.stopPropagation();
      setCurrentApplicant(row);
      setModalInfo({ type: action, data: row });
      setGenericModalOpen(true);
    } else {
      e.stopPropagation();
      setPage(1);
      setNavigateUrl(`/employees/${id}/action/${action}`);
    }
  };

  return (
    <span>
      <TableAction
        color="info"
        icon="info"
        clickAction={(e) => onClickHandler("employeeinfo", e)}
        tooltip="Employee Info"
      />
      {company?.companyType === "Venue" ? (
        <TableAction
          color="info"
          icon="stadium"
          clickAction={(e) => onClickHandler("employeevenues", e)}
          tooltip="Employee Venues"
        />
      ) : (
        <TableAction
          color="info"
          icon="business"
          clickAction={(e) => onClickHandler("employeecustomers", e)}
          tooltip="Employee Customers"
        />
      )}
      <TableAction
        color="warning"
        icon="work"
        clickAction={(e) => onClickHandler("employeejobs", e)}
        tooltip="Employee Jobs"
      />
      <TableAction
        color="success"
        icon="event-seat"
        clickAction={(e) => onClickHandler("employeeevents", e)}
        tooltip={company?.companyType === "Venue" ? "Employee Events" : "Employee Interviews"}
      />
      <TableAction
        color="error"
        icon="mail"
        clickAction={(e) => onClickHandler("employeemail", e)}
        tooltip="Employee Mail"
      />
      <ApplicantMoreButton applicant={row} onSuccess={onRefresh} />
      {/* <TableAction
        color="dark"
        icon="public"
        clickAction={(e) => onClickHandler("employeeall", e)}
        tooltip="All Applicant Information"
      /> */}
    </span>
  );
};

export default EmployeesTableActions;

EmployeesTableActions.defaultProps = {
  id: "",
  setNavigateUrl: () => {},
};

// Typechecking props for the TableAction
EmployeesTableActions.propTypes = {
  id: PropTypes.string,
  setNavigateUrl: PropTypes.func,
};
