import TableAction from "components/TableAction";
import PropTypes from "prop-types";
import { useAppContextController } from "context/AppContext";
import { Diversity3 } from "@mui/icons-material";

const EventsTimePartnersActions = ({
  id,
  row,
  currentEvent,
  setCurrentEvent,
  setGenericModalOpen,
  setModalInfo,
  onOpenMembers,
}) => {
  const { userType } = useAppContextController();
  const getNotesThisEvent = (arr) => {
    let numNotes = 0;
    arr.forEach((item) => {
      if (item?.eventUrl === currentEvent?.eventUrl) numNotes += 1;
    });
    return numNotes;
  };
  const numberEventNotes =
    currentEvent?.eventUrl && row?.notes?.length ? getNotesThisEvent(row.notes) : 0;
  const onClickHandler = (action, e) => {
    switch (action) {
      case "partnerMail":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      case "partnerMembers":
        onOpenMembers(row);
        break;
      case "partnerComment":
        setModalInfo({ type: action, data: row });
        setGenericModalOpen(true);
        break;
      default:
        break;
    }
    e.stopPropagation();
  };

  return (
    <span>
      {userType !== "Client" && userType !== "Event Admin" && (
        <>
          <TableAction
            color="primary"
            icon="mail"
            tooltip={!!row?.leader?.email || !!row?.leader?.phone ? `Send a Message to ${row?.leader?.email ?? row?.leader?.phone}` : "No leader available"}
            clickAction={(e) => onClickHandler("partnerMail", e)}
            disabled={!row?.leader?.email && !row?.leader?.phone}
          />
          &nbsp;&nbsp;
        </>
      )}
      <TableAction
        color="warning"
        icon={<Diversity3 color="warning" />}
        showBadge
        // badgeNumber={numberEventNotes}
        tooltip="Member Attendance"
        clickAction={(e) => onClickHandler("partnerMembers", e)}
      />
      <TableAction
        color="info"
        icon="description"
        showBadge
        badgeNumber={numberEventNotes}
        tooltip="Add a Note"
        clickAction={(e) => onClickHandler("partnerComment", e)}
      />
    </span>
  );
};
export default EventsTimePartnersActions;

EventsTimePartnersActions.defaultProps = {
  id: "",
};

// Typechecking props for the TableAction
EventsTimePartnersActions.propTypes = {
  id: PropTypes.string,
  // children: PropTypes.node.isRequired,
};
