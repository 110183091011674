import { useAuth0 } from "@auth0/auth0-react";
import "@fullcalendar/react/dist/vdom";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import { ThemeProvider } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import MDBox from "components/MDBox";
import PictureAvatar from "components/PictureAvatar";
import ProtectedRoute from "components/ProtectedRoute";
import { setMiniSidenav, setOpenConfigurator, useMaterialUIController } from "context";
import { useAppContextController } from "context/AppContext";
import Configurator from "examples/Configurator";
import Sidenav from "examples/Sidenav";
import NewApplicantPage from "layouts/pages/applicants/new";
import CreateAccount from "layouts/pages/createaccount";
import VerificationMessage from "layouts/pages/createaccount/verifyMessage";
import NewProfile from "layouts/pages/profile";
import JobApply from "layouts/pages/web/JobApply";
import ChangeEmailVerification from "layouts/pages/web/ChangeEmailVerification";
import JobInfoOutsideLogin from "layouts/pages/web/components/JobInfoOutsideLogin";
import Homepage from "layouts/pages/web/home";
import "react-toastify/dist/ReactToastify.css";
import { getCompanyBaseImageUrl } from "utils/helpers/upload";
import useCompanyCache from "hooks/useCompanyCache";
import StoreTokenAndRedirect from "components/StoreTokenAndRedirect";
import routes from "./routes";
import subRoutes from "./subRoutes";

export default function App() {
  const {
    userType,
    currentLoggedUser,
    companyType,
    company,
    setCompany,
    setCompanyType,
    emailTemplates,
  } = useAppContextController();
  const { isLoadingCompany, refetchCompany } = useCompanyCache({
    company,
    setCompany,
    setCompanyType,
  });
  const { isLoading, user } = useAuth0();
  const currentUserRoute = {
    type: "collapse",
    name: `${currentLoggedUser?.firstName} ${currentLoggedUser?.lastName}`,
    key: `${currentLoggedUser?.firstName}-${currentLoggedUser?.lastName}`,
    icon: (
      <PictureAvatar
        image={
          user?.picture && user.email.indexOf("@gmail.com") > -1
            ? user.picture
            : currentLoggedUser?.profileImg
        }
        firstName={currentLoggedUser?.firstName}
        lastName={currentLoggedUser?.lastName}
        userId={currentLoggedUser?._id}
        size="md"
      />
    ),
    userType: ["Master", "Admin", "User"],
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/profile/my-profile",
        component: <NewProfile />,
      },
    ],
  };

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // useEffect(() => {
  //   alert(JSON.stringify(user));
  // }, [user])

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={<ProtectedRoute isAllowed={!!user}>{route.component}</ProtectedRoute>}
            key={route.key}
          />
        );
      }
      return null;
    });

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  const filterRoutes = (route) => {
    if (!route.userType) return true;
    if (route?.companyType && route.companyType !== companyType) return false;
    return route.userType.includes(userType);
  };

  if (isLoading) return <></>;

  if (!user) {
    return (
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" index element={<Homepage />} key="jobs_home" />
          <Route exact path="/jobs/apply/:jobSlug" element={<JobApply />} key="job_apply" />
          <Route exact path="/jobs/apply/id/:id" element={<JobApply />} key="job_apply" />
          {/* <Route exact path="/applicants/code/:applicantCode" element={<NewApplicantPage />} /> */}
          <Route exact path="/applicants" element={<NewApplicantPage />} />
          <Route
            exact
            path="/users/account/id/:id/verify/:otp"
            element={<ChangeEmailVerification />}
          />
          <Route exact path="/jobs/info/:jobSlug" element={<JobInfoOutsideLogin />} />
          <Route exact path="/auth/createaccount" element={<CreateAccount />} />
          <Route exact path="/auth/createaccount/verification" element={<VerificationMessage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && user && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={
              getCompanyBaseImageUrl(company)
                ? `${getCompanyBaseImageUrl(company)}/logo/${company?.logoUrl}`
                : ""
            }
            brandName={company?.name}
            routes={[currentUserRoute, ...routes.filter(filterRoutes)]}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && user && <Configurator />}

      {currentLoggedUser?._id ? (
        <Routes>
          {user && (
            <>
              {getRoutes([currentUserRoute])}
              {getRoutes(routes.filter(filterRoutes))}
              {getRoutes(subRoutes.filter(filterRoutes))}
              <Route
                exact
                path="*"
                element={
                  <ProtectedRoute isAllowed={!!user}>
                    <StoreTokenAndRedirect />
                  </ProtectedRoute>
                }
              />
            </>
          )}
        </Routes>
      ) : null}
      <ToastContainer position="bottom-right" />
      {/* <WebSocketComponent /> */}
    </ThemeProvider>
  );
}
