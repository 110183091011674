import Grid from "@mui/material/Grid";
import fetchCustomers from "api/companies/fetchCustomers";
import { useAppContextController } from "context/AppContext";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomerInfoPanel from "layouts/pages/customers/components/CustomerInfoPanel";
import CompanyMedia from "layouts/pages/companies/components/CompanyMedia";
import CustomerTable from "layouts/pages/customers/components/CustomerTable";
import CustomerFilterCards, {
  initialCards,
} from "layouts/pages/customers/components/CustomerFilterCards";
import ProspectFilterCards from "layouts/pages/customers/components/ProspectFilterCards"; // initialCards,
import ActiveFilterCards from "layouts/pages/customers/components/ActiveFilterCards";
import CustomerEventsTable from "layouts/pages/events/components/EventsTable";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CompanyJobsTable from "layouts/pages/companyjobs/components/CompanyJobsTable";
import useCompanyStore from "stores/useCompanyStore";

import MessagingPanel from "components/MessagingPanel";
import CustomerActivities from "./components/CustomerActivities";
import CustomersApplicantsTable from "./components/CustomerApplicantTable";
import CustomerLocations from "./components/CustomerLocations";
import CustomerContacts from "./components/CustomerContacts";
import CustomerSales from "./components/CustomerSales";
import CustomerMedia from "./components/CustomerMedia";
import CustomerCustomContent from "./components/CustomerCustomContent";
import CustomerSettings from "./components/CustomerSettings";

const CustomerPage = ({ mode = "edit" }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { setDataType } = useCompanyStore((state) => ({
    setDataType: state.setDataType,
  }));

  const { companyType, currentCompany: primaryCompany } = useAppContextController();
  const { id, slug, action } = useParams();
  const [selectedId, setSelectedId] = useState(id);
  // const [selectedSlug, setSelectedSlug] = useState(slug);
  const [actionSelected, setActionSelected] = useState(action || "");
  const [customersPage, setCustomersPage] = useState(1);
  const [refreshCompanies, setRefreshCompanies] = useState(false);
  const [applicantsFilters, setApplicantsFilters] = useState({});
  const [employeesFilters, setEmployeesFilters] = useState({});
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({ status: "Active" });
  const location = useLocation();
  const [useOrTags, setUseOrTags] = useState(true);
  const [selectedPanel, setSelectedPanel] = useState("activities");
  const [selectedContact, setSelectedContact] = useState();
  const { currentCompany, setCurrentCompany } = useCompanyStore((state) => ({
    currentCompany: state.currentCompany,
    setCurrentCompany: state.setCurrentCompany,
  }));

  const { data: customers, isLoading } = useQuery(["customerdropdown", filters], () =>
    fetchCustomers({
      filters,
      fetchAll: true,
      order: "asc",
      orderBy: "name",
    })
  );

  const renderPanel = (selection) => {
    switch (selection) {
      case "activities":
        return <CustomerActivities />;
      case "locations":
        return <CustomerLocations />;
      case "contacts":
        return (
          <CustomerContacts
            setSelectedPanel={setSelectedPanel}
            setSelectedContact={setSelectedContact}
          />
        );
      case "message":
        return (
          <MessagingPanel
            selectedContact={selectedContact}
            currentData={currentCompany}
            parent="Company"
            messageContext="Customer"
            attachmentContext="Customer"
          />
        );
      case "sales":
        return <CustomerSales />;
      case "images":
        return <CustomerMedia />;
      case "customcontent":
        return <CustomerCustomContent />;
      case "settings":
        return <CustomerSettings />;
      default:
        return null;
    }
  };

  useEffect(() => {
    // if (selectedId && (actionSelected || action)) {
    //   setFilters({ _id: selectedId });
    // }
    if (slug && !filters.slug && action) setFilters({ slug });
    if (action !== actionSelected) {
      setActionSelected(action || "");
    }
    if (slug && (!currentCompany || currentCompany.slug !== slug)) {
      const company = customers?.data?.find((customer) => customer.slug === slug);
      if (company) {
        setCurrentCompany(company);
      }
    }
  }, [selectedId, action, actionSelected, id, slug, customers, filters.slug, currentCompany]);

  // causes filter to default to active even if selecting different filter card
  // useEffect(() => {
  //   if (!action && !slug) {
  //     const { filterField, filterValue } = initialCards.find((card) => card.default);
  //     setFilters(() => ({ [filterField]: filterValue }));
  //   }
  // }, [action, slug]);

  useEffect(() => {
    setSelectedContact();
  }, [currentCompany?.slug]);

  useEffect(() => {
    setDataType("customer");
  }, []);

  const setNavigateUrl = (url) => navigate(url);

  const refetchCustomers = async () => {
    await queryClient.invalidateQueries("customers_data");
  };

  return (
    <DashboardLayout stickyNavbar>
      <DashboardNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12}>
          <CustomerFilterCards setFilters={setFilters} setPage={setPage} filters={filters} />
          {filters.status === "Prospect" ? (
            <ProspectFilterCards setFilters={setFilters} setPage={setPage} filters={filters} />
          ) : null}
          {filters.status === "Active" ? (
            <ActiveFilterCards setFilters={setFilters} setPage={setPage} filters={filters} />
          ) : null}
          <CustomerTable
            id={id} // no prop
            slug={slug} // no prop
            filters={filters}
            setFilters={setFilters}
            actionSelected={actionSelected} // no prop
            setNavigateUrl={setNavigateUrl} // no prop
            showFiltersList
            showSearchBar
            refreshCompanies={refreshCompanies}
            title="Customers" // not used
            useOrTags={useOrTags}
            setUseOrTags={setUseOrTags}
          />
        </Grid>
        {["customerjobs", "customerall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CompanyJobsTable
                title={`Customer Jobs at: ${currentCompany?.slug?.toUpperCase()}`}
                filters={currentCompany?.slug ? { companySlug: currentCompany?.slug } : {}}
                showFiltersList={false}
                showSearchBar={false}
                fetchAll
                defaultSort="title"
                parent="Customers"
              />
            </Grid>
          </>
        )}
        {["customerinterviews", "customerall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomerEventsTable
                showFiltersList={false}
                showSearchBar={false}
                title={`Interviews at: ${currentCompany?.slug?.toUpperCase()}`}
                filters={currentCompany?.slug ? { companySlug: currentCompany.slug } : {}}
                navigateToAction="eventsinfo"
                // setOpen={(isOpen) => {
                //   if (isApplicantEventVenuePending() && isEmployeeUser(userType)) {
                //     setOpenEventPreview(false);
                //     toggleMessageModal(true);
                //     return;
                //   }
                //   setOpenEventPreview(isOpen);
                // }}
                defaultSort="eventDate"
                // setEventPreview={setEventPreview}
                parent="Events"
              />
            </Grid>
          </>
        )}
        {["customerinfo", "customerall", ""].includes(actionSelected) &&
          (slug || mode === "create") && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  <CustomerInfoPanel
                    setRefreshCompanies={setRefreshCompanies}
                    handleSubmit={() => refetchCustomers()}
                    selectedPanel={selectedPanel}
                    onPanelSelect={setSelectedPanel}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6}>
                  {renderPanel(selectedPanel)}
                </Grid>
              </Grid>
            </Grid>
          )}

        {["customeremployees", "customerall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomersApplicantsTable
                type="Staffing"
                showFiltersList
                showSearchBar={false}
                fetchAll
                filters={
                  slug
                    ? { "companies.companySlug": slug, ...employeesFilters }
                    : { ...employeesFilters }
                }
                title={`Staffing Pool at: ${currentCompany?.slug?.toUpperCase()}`}
                navigateToAction="employeeinfo"
                defaultSort="createdDate"
                customerSlug={slug}
                showEventStatus
                pageSize={50}
                page={customersPage}
                setPage={setCustomersPage}
                messageContext="Customer"
                customerId={currentCompany?._id}
                staffingPoolName={currentCompany?.name}
              />
            </Grid>
          </>
        )}
        {["customerapplicants", "customerall"].includes(actionSelected) && (
          <>
            <Grid item xs={12} sm={12} md={12}>
              <CustomersApplicantsTable
                type="Applicants"
                showFiltersList
                showSearchBar={false}
                fetchAll
                filters={
                  slug
                    ? { "companies.companySlug": slug, ...applicantsFilters }
                    : { ...applicantsFilters }
                }
                title={`Customer Applicants at: ${currentCompany?.slug?.toUpperCase()}`}
                navigateToAction="applicantinfo"
                venueSlug={slug}
                showEventStatus
                pageSize={50}
                page={customersPage}
                setPage={setCustomersPage}
                messageContext="Customer"
                customerId={currentCompany?._id}
              />
            </Grid>
          </>
        )}
        {["customermedia"].includes(actionSelected) && slug && (slug || mode === "create") && (
          <Grid item xs={12}>
            <CompanyMedia
              company={currentCompany}
              sourceComponent="Customer"
              refetchCompany={() => refetchCustomers()}
            />
          </Grid>
        )}

        {/* // TODO: need to move this to Events Page
        <Grid item xs={12} sm={12}>
          <InterviewEditor />
        </Grid> */}
      </Grid>
    </DashboardLayout>
  );
};

export default CustomerPage;
