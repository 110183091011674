const timeSheetFields = [
  {
    name: "venueId",
    label: "Venue ID",
  },
  {
    name: "eventId",
    label: "Event ID",
  },
  {
    name: "eventName",
    label: "Event Name",
  },
  {
    name: "eventDate",
    label: "Event Date",
  },
  {
    name: "employeeId",
    label: "Employee ID",
  },
  {
    name: "firstName",
    label: "Employee First Name",
  },
  {
    name: "lastName",
    label: "Employee Last name",
  },
  {
    name: "payRate",
    label: "Pay Rate",
  },
  {
    name: "billRate",
    label: "Bill Rate",
  },
  {
    name: "position",
    label: "Position",
  },
  {
    name: "timeIn",
    label: "Time In",
  },
  {
    name: "timeOut",
    label: "Time Out",
  },
  {
    name: "partnerSlug",
    label: "Partner Slug",
  },
  {
    name: "partnerName",
    label: "Partner Name",
  },
];

const commonFields = [
  {
    name: "venueName",
    label: "Venue Name",
  },
  {
    name: "eventName",
    label: "Event Name",
  },
  {
    name: "eventId",
    label: "Event Id",
  },
  {
    name: "eventDate",
    label: "Event Date",
  },
];

const eventRosterFields = [
  ...commonFields,
  {
    name: "firstName",
    label: "Employee First Name",
  },
  {
    name: "lastName",
    label: "Employee Last Name",
  },
  {
    name: "phone",
    label: "Employee Phone Number",
  },
  {
    name: "email",
    label: "Employee Email Address",
  },
  {
    name: "rank",
    label: "Employee Rank",
  },
  {
    name: "position",
    label: "Position",
  },
  {
    name: "hireDate",
    label: "Hire Date",
  },
  {
    name: "birthDate",
    label: "Birth Date",
  },
  {
    name: "timeIn",
    label: "Time In",
  },
  {
    name: "timeOut",
    label: "Time Out",
  },
  {
    name: "partnerSlug",
    label: "Partner Slug",
  },
  {
    name: "partnerName",
    label: "Partner Name",
  },
];

const eventFields = [
  ...commonFields,
  {
    name: "positionsRequested",
    label: "# Positions Requested",
  },
  {
    name: "numberOnRoster",
    label: "# On Roster",
  },
  {
    name: "numberOnWaitlist",
    label: "# Waitlisted",
  },
  {
    name: "numberOnPremise",
    label: "# On Premise",
  },
  {
    name: "reportTime",
    label: "Report Time",
  },
  {
    name: "endTime",
    label: "End Time",
  },
];

export { timeSheetFields, eventRosterFields, eventFields };
