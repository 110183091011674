import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { SnackbarProvider, useSnackbar } from "notistack";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import FlagIcon from "@mui/icons-material/Flag";
import { Handshake, Lock, LockOpen } from "@mui/icons-material";
import { Box } from "@material-ui/core";

import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import OnboardingStatus from "components/OnboardingStatus";
import PictureAvatar from "components/PictureAvatar";
import SmartPhoneColumn from "components/SmartPhoneColumn";
import { useAppContextController } from "context/AppContext";
import useSort from "utils/useSort";
import EmployeeStatus from "components/EmployeeStatus";
import EventsTimeActions from "layouts/pages/events/components/EventsTimeActions";
import RosterPosition from "layouts/pages/events/components/RosterPosition";
import EventStatusButton from "components/EventStatusButton";
import VenueStatusButton from "components/VenueStatusButton";
import { baseAxios } from "config";
import { useEventContext } from "context/EventContext";
import fetchApplicants from "layouts/pages/applicants/actions/fetchApplicants";
import fetchEmployees from "layouts/pages/employees/actions/fetchEmployees";
import updateEventRoster from "layouts/pages/events/actions/updateEventRoster";
import rosterClockIn from "layouts/pages/events/actions/rosterClockIn";
import EventActionModal from "layouts/pages/events/components/EventActionModal";
import { attended, getApplicantVenueStatus } from "utils/helpers/applicant";
import useSessionAuth from "hooks/useSessionAuth";
import fetchPartners from "layouts/pages/partners/actions/fetchPartners";
import fetchStaffingPool from "layouts/pages/employees/actions/fetchStaffingPool";
import PartnerIcon from "components/PartnerIcon";
import InterviewAttended from "../InterviewAttended";
import TimeInOut from "../TimeInOut";
import EventsTimePartnersActions from "../EventsTimePartnersActions";
import EventPartnerActionModal from "../EventPartnerActionModal";
import fetchEventMembers from "../../actions/fetchEventMembers";
import EventsTimeMembersActions from "../EventsTimeMembersActions";

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    whiteSpace: "pre-line",
  },
});

const EventRosterMembersTable = ({
  defaultSort = "lastName",
  allApplicantsRoster,
  requeryRoster,
  toggleRefreshBadges,
  onOpenMembers,
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { setEventRoster } = useEventContext();
  const { closeSnackbar } = useSnackbar();

  const { venues, setCurrentEvent, currentEvent, userType, currentLoggedUser } =
    useAppContextController();
  const [venueSlug, setVenueSlug] = useState(currentEvent ? currentEvent.venueSlug : null);
  const [mongoId, setMongoId] = useState(currentEvent ? currentEvent._id : null);
  const { eventUrl } = useParams();
  const [modalInfo, setModalInfo] = useState({ data: currentEvent });
  const [genericModalOpen, setGenericModalOpen] = useState(false);
  const [showTimeColumns, setShowTimeColumns] = useState(true);

  // Pagination state
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20); // Default pageSize higher than 10. Don't use preferred pageSize

  const { order, orderBy, toggleSort } = useSort();

  // Member information
  const applicantOptions = {
    fetchAll: false,
    page,
    limit,
    order,
    orderBy,
    eventUrl: currentEvent?.eventUrl,
  };

  const {
    data: applicants,
    isLoading: appLoading,
    isRefetching: isRefetchingApps,
    refetch: requeryApplicants,
  } = useQuery(["eventMembers", applicantOptions], () => fetchEventMembers(applicantOptions));
  // ------------------

  const [toastAlert, setToastAlert] = useState(false);

  const eventVenue = useMemo(
    () => venues?.[currentEvent?.venueSlug] || {},
    [currentEvent?.venueSlug, venues]
  );

  const columns = useMemo(
    () => [
      {
        title: "Roster",
        field: "venues",
        width: 120,
        customCell: (check, field, row) => {
          const rosterData = allApplicantsRoster?.result ?? [];
          const eventApplicant = rosterData.find((item) => item.id === row?._id) || null;
          const eventStatus = eventApplicant?.status || "Not Roster";
          const applicantVenueStatus = getApplicantVenueStatus(row, currentEvent?.venueSlug);
          const isInvalid = applicantVenueStatus === "Locked" || row?.recordLocked === "Yes";
          return (
            <>
              <EventStatusButton
                status={eventStatus}
                isInvalid={isInvalid}
                applicant={row}
                event={{ _id: currentEvent?._id, eventUrl, applicants: rosterData }}
                onUpdate={() => {
                  requeryRoster();
                  toggleRefreshBadges?.();
                }}
                iconType="distinct"
                isMember
              />
            </>
          );
        },
      },
      {
        title: "Avatar",
        field: "partnerLogo",
        width: 120,
        customCell: (image, field, row) => (
          <PartnerIcon
            logoUrl={row?.partner?.partnerLogo}
            slug={row?.partner?.slug}
            name={row?.partner?.name}
            goToEnabled={false}
          />
        ),
      },
      {
        title: "Status",
        field: "status",
        width: 120,
        customCell: (image, field, row) => <Handshake fontSize="large" color="primary" />,
      },
      {
        title: "Venue",
        field: "venueSlug",
        customCell: (vslug, field, row) => (
          <>
            <VenueStatusButton
              status={getApplicantVenueStatus(row.partner, currentEvent?.venueSlug)}
              venue={eventVenue}
              onUpdate={() => requeryApplicants()}
              applicant={row.partner}
              isPartner
              viewOnly
            />
          </>
        ),
      },
      {
        title: "Organization",
        field: "name",
        customCell: (vslug, field, row) => row.partner?.name,
      },
      {
        title: "Last Name",
        field: "lastName",
      },
      {
        title: "First Name",
        field: "firstName",
      },
      {
        title: "Email",
        field: "email",
      },
      {
        title: "Phone",
        field: "phone",
      },
      {
        title: "T-Shirt Size",
        field: "tShirtSize",
      },
      {
        title: "Position",
        field: "position",
        customCell: (vslug, field, row) => row.partner?.primaryPosition,
      },
      {
        title: "Actions",
        field: "action",
        customCell: (id, field, row) => (
          <EventsTimeMembersActions
            id={id}
            row={row}
            currentEvent={currentEvent}
            setCurrentEvent={setCurrentEvent}
            setModalInfo={setModalInfo}
            // setNavigateUrl={setNavigateUrl}
            setGenericModalOpen={setGenericModalOpen}
            onOpenMembers={onOpenMembers}
          />
        ),
      },
    ],
    [
      allApplicantsRoster?.result,
      currentEvent,
      eventUrl,
      requeryRoster,
      toggleRefreshBadges,
      eventVenue,
      requeryApplicants,
      setCurrentEvent,
      onOpenMembers,
    ]
  );

  return (
    <>
      {" "}
      <SnackbarProvider
        maxSnack={3}
        open={toastAlert}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setToastAlert(false)}
        key="top-center"
      >
        <MDBox pt={2} pb={2}>
          <DataTable
            columns={columns}
            data={!applicants ? { data: [] } : applicants}
            page={page}
            limit={limit}
            setPage={setPage}
            setLimit={setLimit}
            order={order}
            orderBy={orderBy}
            toggleSort={toggleSort}
            defaultSort={defaultSort}
            isLoading={appLoading}
            greybar
            divider
            idField="email"
          />
        </MDBox>
        <EventActionModal
          open={genericModalOpen}
          setOpen={setGenericModalOpen}
          modalInfo={modalInfo}
          setModalInfo={setModalInfo}
          setToastAlert={setToastAlert}
          toggleReadApps={requeryApplicants}
        />
      </SnackbarProvider>
    </>
  );
};
EventRosterMembersTable.defaultProps = {};

// Typechecking props
EventRosterMembersTable.propTypes = {};

export default EventRosterMembersTable;
